import { Row, Col } from 'react-bootstrap';
import TeamCard from './TeamCard/TeamCard';
import LinkCard from './LinkCard/LinkCard';
import { RankInterface, TeamInterface, UserInterface } from '../Interfaces';
import './ProfileData.css';

interface Props {
    user: UserInterface,
    teams: Array<TeamInterface>,
    ranks: Array<RankInterface>
};

function ProfileData({ user, teams, ranks }: Props) {
    const token = localStorage.getItem('token');

    return (
        <div className='px-md-5 pt-4'>
            <p className='text red publicText'>This data is public</p>
            <p className='text red justify'>Profile Name</p>
            <h3 className='text white justify'>{user.profileName}</h3>

            <div className='pb-4'>
                <p className='text red justify'>About You</p>
                <p className='text white medium justify'>{user.description || "Edit your profile to add a description."}</p>
            </div>

            <Row className='p-0 cols-2 justify-content-start'>
                <Col xs={4} md={2}>
                    <p className='text red justify'>RL rank</p>
                    <p className='text red justify'>CSGO rank</p>
                    <p className='text red justify'>LOL rank</p>
                </Col>
                <Col xs={8} md={10}>
                    { ranks.map(x => x.game).indexOf("RL") == -1 ? <p className='text white justify'>n/a</p> : <p className='text white justify'>{ranks[ranks.map(x => x.game).indexOf("RL")]?.rank}</p>}
                    { ranks.map(x => x.game).indexOf("CS") == -1 ? <p className='text white justify'>n/a</p> : <p className='text white justify'>{ranks[ranks.map(x => x.game).indexOf("CS")]?.rank}</p>}
                    { ranks.map(x => x.game).indexOf("LOL") == -1 ? <p className='text white justify'>n/a</p> : <p className='text white justify'>{ranks[ranks.map(x => x.game).indexOf("LOL")]?.rank}</p>}
                </Col>
            </Row>

            <div className='pb-3' id='parent'>
                <p className='text white medium justify'>Your Teams</p>
                {teams.length == 0 && <p className='text white medium justify'>You are not yet enrolled in any teams.</p>}
                {teams.map((t, idx) => <TeamCard key={idx} team={t} />)}
            </div>


            {ranks.map((r, idx) => (
                <div className='pb-2'>
                    <LinkCard key={idx} rank={r} />
                </div>
            ))}
            <div className='pt-3'></div>

        </div>
    );
}

export default ProfileData;
