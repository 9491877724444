import { useState } from "react";
import { Image, Col, Row, Tab, Nav, Container } from "react-bootstrap";
import "./Rules.css";
import { LeagueOfLegendsRules, CSGORUles, RocketLeagueRules, FIFARules } from "./Rule_contents";

const rulesData = [
    { id: 0, name: "League of Legends", content: <LeagueOfLegendsRules />},
    { id: 1, name: "Counter-Strike 2", content: <CSGORUles />},
    { id: 2, name: "Rocket League", content: <RocketLeagueRules />},
    { id: 3, name: "EA Sports FC 24", content: <FIFARules />}
];

function Rules() {
    const [activeRule, setActiveRule] = useState(0);
    
    const renderRuleButton = (rule: any) => (
        <button
            className={`my-2 blur-bg text medium event-button px-4 py-2 ${rule.id === 0 ? 'me-lg-2' : 'mx-lg-2'} ${activeRule === rule.id ? 'active-rule' : ''}`}
            onClick={() => setActiveRule(rule.id)}
        >
            {rule.name}
        </button>
    );

    return (
        <div className="w-90 m-auto my-5">
            <h1 className="text white pb-3 large">Event Rules</h1>
            <div className="description">
                <p className="text m-auto white my-2">Choose rule:</p>
                <div>
                    {rulesData.map(renderRuleButton)}
                </div>
                <div className="w-100 h60 blur-bg text-wrap text-break border border-danger medium scrollable mt-3 px-4 py-2">
                    {rulesData.find(rule => rule.id === activeRule)?.content}
                </div>
            </div>
        </div>
    );
}

export default Rules;