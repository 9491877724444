import { UserInterface } from '../Interfaces';
import UserIcon from '../../../images/UserIcon.png';
import PhoneIcon from '../../../images/PhoneIcon.png';
import EmailIcon from '../../../images/EmailIcon.png';
import { Image, Col, Row, Tab, Nav, Container } from "react-bootstrap";
import './ProfilePrivateData.css';

function ProfilePrivateData(user: UserInterface) {
    return (
        <div className='container-private-profile right-side border border-danger blur-bg mb-3 px-md-5 pt-4'>
            <p className='text red privateText'>This data is private</p>
            <Row className="px-0 pb-0 pt-2 justify-content-between row-cols-1 row-cols-md-2">
                <Col className='m-0 p-md-0 pl-3'>
                    <p className='text red justify'><img src={UserIcon} alt=""/> First Name</p>
                    <p className='text white medium justify'>{user.firstName}</p>
                </Col>
                <Col className='m-0 p-md-0 pl-3'>
                    <p className='text red justify'><img src={UserIcon} alt=""/> Last Name</p>
                    <p className='text white medium justify'>{user.lastName}</p>
                </Col>
            </Row>
            <Row className='px-0 pb-0 pt-2'>
                <Col className="p-md-0 pl-3">
                    <p className='text red justify'><img src={PhoneIcon} alt=""/> Phone number</p>
                    <p className='text white medium justify'>{user.phone}</p>
                </Col>
            </Row>
            <Row className='px-0 pb-0 pt-2'>
                <Col className="p-md-0 pl-3">
                    <p className='text red justify'><img src={EmailIcon} alt=""/> Email Address</p>
                    <p className='text white medium justify pb-3'>{user.email}</p>
                </Col>
            </Row>
        </div>
    )
}

export default ProfilePrivateData;