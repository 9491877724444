import { Image, Col, Row } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import CustomButton from "../../../common/CustomButton";
import axios from "axios";
import { useEffect, useState } from "react";
import DragAndDrop from "../../../common/DragAndDrop/DragAndDrop";
import JoinRequestCard from "./JoinRequestCard/JoinRequestCard";

import "./EditTeam.css"

import lol from "../../../images/lol.svg";
import csgo from "../../../images/csgo.svg";
import rl from "../../../images/rl.svg";
import { JoinRequestInterface, TeamInterface, UserInterface } from "../Interfaces";
import * as Yup from 'yup';

interface Props {
    user: UserInterface,
    teams: Array<TeamInterface>
};

function ProfileEditTeam({ user, teams }: Props) {
    const token = localStorage.getItem('token');
    const [teamIndex, setTeamIndex] = useState(0);
    const [error, setError] = useState("");

    const comps = new Map<String, any>([
        ["LOL", lol],
        ["CS", csgo],
        ["RL", rl],
    ])

    const [requests, setRequests] = useState(Array<JoinRequestInterface>);

    const select = function (idx: number) {
        const activeElements = document.getElementsByClassName("active-team")
        for (let i = 0; i < activeElements.length; i++) {
            activeElements.item(i)?.classList.remove("active-team");
        }
        document.getElementById("team" + idx.toString())?.classList.add("active-team");

        setTeamIndex(idx);

        axios({
            method: "get",
            url: process.env.REACT_APP_URL + "/join-request/team/" + teams[idx]?.id,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            setRequests(res.data.map((request: any) => {
                return {
                    id: request.id,
                    gameUsername: request.gameUsername,
                    playerRank: request.playerRank,
                    playerPhone: request.playerPhone,
                    status: request.status
                }
            }))
        }).catch(e => {
            console.log(e);
        })
    }

    function onClickAccept(id: string) {
        axios({
            method: "patch",
            url: process.env.REACT_APP_URL + "/join-request/accept/" + id,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {

        }).catch(e => {
            console.log(e);
            setError(e.response.data.message);
        })
    }

    function onClickDecline(id: string) {
        axios({
            method: "patch",
            url: process.env.REACT_APP_URL + "/join-request/decline/" + id,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {

        }).catch(e => {
            console.log(e);
        })
    }

    useEffect(() => {
        select(0);
    }, [teams])

    return (
        <div className='px-md-3 pt-4'>
            {teams.length == 0 && <p className='text white medium justify'>You are not the captain of any team.</p>}
            {teams.length != 0 && <Formik
                initialValues={{
                    name: teams[teamIndex]?.name,
                    description: teams[teamIndex]?.description,
                    teamEditImage: teams[teamIndex]?.image
                }}
                validationSchema={Yup.object().shape({
                    description: Yup.string().max(5000),
                    name: Yup.string().max(50).required("Name missing"),
                    teamEditImage: Yup.mixed().test(
                        "size",
                        "Image size cannot exceed 1Mb!",
                        (val: any) => {
                            console.log(val?.size)
                            return !val || !val?.size || val?.size < 1_000_000
                        }
                    ),
                })}
                enableReinitialize
                onSubmit={(values, { setSubmitting, setErrors }) => {
                    let form = new FormData();
                    for (let [key, value] of Object.entries(values)) {
                        if (key == "teamEditImage" && value) form.append("image", value);
                        else if (value) form.append(key, value);
                    }

                    if (values.teamEditImage == teams[teamIndex]?.image) {
                        form.delete("image");
                    }

                    axios.patch(process.env.REACT_APP_URL + "/teams/" + teams[teamIndex].id, form, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            "Authorization": `Bearer ${token}`
                        }
                    })
                        .then(() => {
                            setErrors({ description: "Successfully edited team!" });
                            setSubmitting(false);
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                        })
                        .catch(e => {
                            // setErrors({description: e.response.data.errors.name[0]});
                            setSubmitting(false);
                        });
                }}
            >
                {({ errors }) => (
                    <Form >
                        <div className='py-4'>
                            <Row className="gy-3 justify-content-around">
                                {
                                    teams.map((team, idx) =>
                                        <Col className="text-center" key={idx} md={3} sm={6} xs={6}>
                                            <div className="p-2 border border-danger">
                                                {idx == 0 ?
                                                    <Image
                                                        className="team-image p-2 w-100 active-team"
                                                        src={comps.get(team.competition)} alt={comps.get(team.competition)} id={"team" + idx.toString()}
                                                        onClick={() => { select(idx) }} />
                                                    : <Image
                                                        className="team-image p-2 w-100"
                                                        src={comps.get(team.competition)} alt={comps.get(team.competition)} id={"team" + idx.toString()}
                                                        onClick={() => { select(idx) }} />}
                                            </div>
                                        </Col>
                                    )
                                }
                            </Row>
                        </div>

                        <p className="text white text-start medium">Team Name</p>
                        <Field className="autofill mb-3 ps-3 py-2 w-100 blur-bg border border-danger text white small-medium transparent-background"
                            type="text"
                            name="name"
                            placeholder="Team Name" />
                        <p className="text white medium justify">About Your Team</p>
                        <Field className="autofill mb-3 ps-3 pt-2 pb-2 w-100 blur-bg border border-danger text white small-medium transparent-background message-box"
                            type="text"
                            name="description"
                            placeholder="About Your Tean"
                            as="textarea"
                            rows="6" />
                        <p className="text white text-start medium">Change Team Logo</p>
                        <Field
                            name="teamEditImage"
                            component={DragAndDrop}
                            accept="image/*"
                            margin="normal"
                        />

                        <div className="pt-3" />
                        <CustomButton><p className='text white medium justified p-1'>Update Team</p></CustomButton>
                        <div className="py-0" />

                        <div className='py-4'>
                            {requests.map((r, idx) => (
                                <div key={idx}>
                                    <JoinRequestCard request={r} onClickAccept={onClickAccept} onClickDecline={onClickDecline} />
                                    {error && <p className='text red small text-center'>{error}</p>}
                                </div>
                            ))}
                        </div>

                        {/* {errors && <p className="text red medium text-start">{errors.image}</p>} */}
                        {errors && <p className="text red medium text-center">{errors.description}</p>}
                    </Form>
                )}
            </Formik>}


        </div>
    );
}

export default ProfileEditTeam;