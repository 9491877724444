import Hama from "./logo hama alb.svg";
import PCGarage from "./logo_PCGarage_alb.svg";
import Trust from "./trust5.svg";
import Doritos from "./Logo_Doritos.png";
import Bose from "./Bose.png";
import WCR from "./wcr logo transparent.png";
import Skeen from "./SKEEN.png";
import Titi from "./Logo ATA Black Bck.png";
import Captive from "./Logo Breakout Captive modificat.png";
import Breackout from "./Logo Captive final negativ.png";

export const sponsors = {
  Diamond: [
    {
      name: "Hama",
      path: Hama,
      description: "",
    },
    {
      name: "Trust",
      path: Trust,
      description: "",
    },
    {
      name: "Bose",
      path: Bose,
      description: "",
    },
  ],

  Platinum: [
    {
      name: "PC Garage",
      path: PCGarage,
      description: "",
    },
    {
      name: "Doritos",
      path: Doritos,
      description: "",
    },
    // {
    //   name: "Alienware",
    //   path: "",
    //   description: "",
    // },
    // {
    //   name: "Heineken",
    //   path: "",
    //   description: "",
    // },
  ],
  Gold: [
    {
      name: "TitiAur",
      path: Titi,
      description: "",
    },
    // {
    //   name: "Skullcandy",
    //   path: "",
    //   description: "",
    // },
  ],
  Silver: [
    {
      name: "WCR",
      path: WCR,
      description: "",
    },
    {
      name: "Skeen",
      path: Skeen,
      description: "",
    },
    {
      name: "Captive",
      path: Captive,
      description: "",
    },
    {
      name: "Breackout",
      path: Breackout,
      description: "",
    },
  ],
};
