import { Formik, Form, Field } from "formik";
import CustomButton from "../../../common/CustomButton";
import axios from "axios";
import { useEffect, useState } from "react";
import { JoinRequestInterface } from "../Interfaces";
import UserJoinRequestCard from "./UserJoinRequestCard/UserJoinRequestCard";

function ProfileJoinTeam() {
    const token = localStorage.getItem('token');
    const [requests, setRequests] = useState(Array<JoinRequestInterface>);


    useEffect(() => {
        axios({
            method: "get",
            url: process.env.REACT_APP_URL + "/join-request/user",
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            setRequests(res.data.map((request: any) => {
                return {
                    id: request.id,
                    gameUsername: request.gameUsername,
                    playerRank: request.playerRank,
                    playerPhone: request.playerPhone,
                    status: request.status
                }
            }))
        }).catch(e => {
            console.log(e);
        })
    }, [])

    return (
        <div className='px-md-3 pt-4'>
            <Formik
                initialValues={{
                    invite_token: ""
                }} enableReinitialize
                onSubmit={(values, { setSubmitting, setErrors }) => {
                    console.log("submitted", values);
                    setSubmitting(false);
                    axios.post(process.env.REACT_APP_URL + "/teams/join/" + values.invite_token, values, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            "Authorization": `Bearer ${token}`
                        }
                    }).then(() => {
                        setErrors({ invite_token: "Successfully joined team!" });
                        setSubmitting(false);
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    })
                        .catch(e => {
                            setErrors({ invite_token: e.response.data.errors.invite_token[0] });
                        });
                }}
            >
                {({ errors }) => (
                    <Form >
                        <p className="text white text-start medium">Registration is closed</p>
                        {/* <Field className="autofill mb-3 ps-3 py-2 w-100 blur-bg border border-danger text white small-medium transparent-background"
                            type="text"
                            name="invite_token"
                            placeholder="Insert token here..." />
                        {errors && <p className="text red medium text-start pb-2">{errors.invite_token}</p>}
                        <CustomButton><p className='text white medium justified'>Join Team</p></CustomButton> */}
                    </Form>
                )}
            </Formik>
            {/* <p className="text white text-start medium pb-4">Team applications</p>
            {requests.map((r, idx) => (
                <UserJoinRequestCard key={idx} request={r} />
            ))} */}
        </div>
    );
}

export default ProfileJoinTeam;