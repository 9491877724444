import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CustomButton from '../../common/CustomButton';
import axios from 'axios';
import * as Yup from 'yup';

function ResetPassword() {
  const search = useLocation().search;

  const [forgotError, setForgotError] = useState('');
  const { resetToken } = useParams();
  const nav = useNavigate();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string().required('Password missing'),
        password_confirmation: Yup.string().test(
          'passwords-match',
          'Passwords must match',
          function (value) {
            return this.parent.password === value;
          },
        ),
      }),
    [],
  );

  const handleResetPass = async (
    values: { password: string; password_confirmation: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/reset-password/${resetToken}`,
        {
          token: resetToken,
          password: values.password,
          password_confirmation: values.password_confirmation,
        },
      );
      setForgotError('Password has been reset successfully!');
      setSubmitting(false);
      setTimeout(() => {
        nav('/');
      }, 2000);
    } catch (error: any) {
      setForgotError(error.response.data.message);
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <div className="align-items-center border border-margin border-danger px-md-5 py-2">
        <Formik
          initialValues={{
            password: '',
            password_confirmation: '',
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) =>
            handleResetPass(values, { setSubmitting })
          }
        >
          {(formik) => (
            <div className="ps-3 pe-3 pb-2">
              <p className="text text-center white large justify w700 p-3">
                Reset Password
              </p>
              <Form>
                <Row className="mb-3 justify-content-around">
                  <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                    <p className="ms-2 text white medium justify w700">
                      Password
                    </p>
                    <Field
                      className="p-2 border-margin border-danger text white small transparent-background large-field"
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                    <ErrorMessage name="password">
                      {(msg) => (
                        <p className="ms-2 text red small justify">{msg}</p>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
                <Row className="mb-3 justify-content-around">
                  <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                    <p className="ms-2 text white medium justify w700">
                      Confirm Password
                    </p>
                    <Field
                      className="p-2 border-margin border-danger text white small transparent-background large-field"
                      type="password"
                      name="password_confirmation"
                      placeholder="Confirm Password"
                    />
                    <ErrorMessage name="password_confirmation">
                      {(msg) => (
                        <p className="ms-2 text red small justify">{msg}</p>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
                {forgotError && (
                  <p className="text text-center red medium text-start pb-2">
                    {forgotError}
                  </p>
                )}
                <CustomButton>
                  <p className="text white medium justified mx-4">
                    Update Password
                  </p>
                </CustomButton>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </Container>
  );
}

export default ResetPassword;
