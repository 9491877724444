import { Nav, Navbar } from "react-bootstrap";
import CustomButton from "../../common/CustomButton";
import "./Header.css";
import logo from "../../images/logo.png";
import searchLogo from "../../images/search-logo.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

function Header() {
  const nav = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if(!token) {
      setLoggedIn(false);
      return;
    }

    axios({
      method: "get",
      url: process.env.REACT_APP_URL + "/profile/myProfile",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setLoggedIn(true);
      })
      .catch((e) => {
        setLoggedIn(false);
        localStorage.removeItem("token");
      });
  }, []);

  return (
    <Navbar expand="xl">
      <Navbar.Brand>
        <a href="/">
          <img src={logo} className="logo pe-4"></img>
        </a>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="nav-content justify-content-between">
          <Nav.Link
            className="px-5 nav-link-element nav-link-element-left d-flex flex-row align-items-end text medium"
            href="/"
          >
            Home
          </Nav.Link>
          <Nav.Link
            className="px-5 nav-link-element text medium d-flex flex-row align-items-end"
            href="/leaderboard"
          >
            Leaderboard
          </Nav.Link>
          <Nav.Link
            className="px-5 nav-link-element text medium d-flex flex-row align-items-end"
            href="/rules"
          >
            Rules
          </Nav.Link>
          <Nav.Link
            className="px-3 nav-link-element nav-link-element-right d-flex flex-row align-items-end text medium"
            href="/search"
          >
            <img src={searchLogo} alt="search" />
            <div className="nonestuff ps-3">Search profile / team...</div>
          </Nav.Link>
          <div className="mt-xl-0 mt-3">
            {loggedIn ? (
              <CustomButton clickFunc={() => nav("/profile")}>
                <p className="text white medium px-4">Profile </p>
              </CustomButton>
            ) : (
              <CustomButton clickFunc={() => nav("/login")}>
                <p className="text white small-medium px-md-3 py-1">
                  Register / Login
                </p>
              </CustomButton>
            )}
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
