import './Search.css';

import {useEffect, useState} from "react";
import axios from "axios";
import searchLogo from "../../images/search-logo.svg";
import danger from "../../images/danger.svg";
import imageMargin from "../../images/image-margin.png";
import backButton from "../../images/back-button.svg";
import {useMediaQuery} from "react-responsive";
import PanelFooter from 'react-bootstrap/lib/PanelFooter';
import { Pagination } from 'react-bootstrap';
import LinkCard from '../Profile/Data/LinkCard/LinkCard';

function Search() {
    const initialInputValue = 'Search profile / team...';
    const [inputValue, setInputValue] = useState(initialInputValue);
    const [displayTeams, setDisplayTeams] = useState(false);
    const [displayProfiles, setDisplayProfiles] = useState(false);
    const [teams, setTeams] = useState<any[]>([]);
    const [profiles, setProfiles] = useState<any[]>([]);
    const [selectedId, setSelectedId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [members, setMembers] = useState<any[]>([]);
    const [teamShown, setTeamShown] = useState(false);
    const [profileShown, setProfileShown] = useState(false);
    const [competition, setCompetition] = useState('');
    const [competitionInitials, setCompetitionInitials] = useState('');
    const [loading, setLoading] = useState(false);
    const [all, setAll] = useState<any[]>([]);
    const [displayPages, setDisplayPages] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalTeams, setTotalTeams] = useState(0);
    const [teamId, setTeamId] = useState('');
    const [userGameRank, setUserGameRank] = useState('');
    const [isRecruting, setIsRecruting] = useState(true);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const token = localStorage.getItem("token");

    const gameNames = {
        "LOL": "League of Legends",
        "RL": "Rocket League",
        "CS": "Counter-Strike",
    };

    const gamePlayers = {
        "LOL": 5,
        "RL": 2,
        "CS": 5,
    };

    const [gameRanks, setGameRanks] = useState<any[]>([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1,
        pageSize: 10,
    } as any);

    useEffect(() => {
        axios({
          method: "get",
          url: process.env.REACT_APP_URL + "/profile/myProfile",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then(res => {
          setLoggedIn(true);
        }).catch(e => {
          setLoggedIn(false);
          localStorage.removeItem("token");
        })
      }, [])

    const isSmall = useMediaQuery({ query: '(max-width: 992px)' });
    
    const searchTeams = () => {
        setDisplayTeams(true);
        setDisplayProfiles(false);
        setDisplayPages(true);
        setSelectedId('');
    
        setLoading(true);
        axios.get(process.env.REACT_APP_URL + "/search/teams", {
            params: {
                page: Number(currentPage),
                pageSize: Number(pageSize)
            }
        }).then(res => {
            setAll(res.data.teams);
            setLoading(false);
            setPagination(res.data.pagination);
            setTotalTeams(res.data.totalTeams);
        });
    };

    const searchProfiles = () => {
        setDisplayTeams(false);
        setDisplayProfiles(true);
        setDisplayPages(true);
        setSelectedId('');

        const searchPrompt = inputValue === initialInputValue ? '' : inputValue.toLowerCase();

        setLoading(true);
        axios.get(process.env.REACT_APP_URL + "/search/users", {
            params: {
                page: currentPage,
                pageSize: pageSize
            }
        }).then(res => {
            setAll(res.data.users);
            setPagination(res.data.pagination);
            setTotalUsers(res.data.totalUsers);
            setProfiles([
                ...res.data.users.filter((user: any) => user.profileName && user.profileName.toLowerCase().indexOf(searchPrompt) === 0),
                ...res.data.users.filter((user: any) => user.profileName && user.profileName.toLowerCase().indexOf(searchPrompt) > 0),
            ]);
            setLoading(false);
        });
    };

    const displayTeam = (team: any) => {
        console.log(team);
        setTeamShown(true);
        setProfileShown(false);
        setLoading(false);
      
        setName(team.name);
        setDescription(team.description);
        setImage(team.imageUrl);
        setCompetition(gameNames[team.games as keyof typeof gameNames]);
        setCompetitionInitials(team.games);

        setTeamId(team.id);

        if(team.numberOfUsers < gamePlayers[team.games as keyof typeof gamePlayers]){
            setIsRecruting(true);
        }
        else
            setIsRecruting(false);
      
        if (team.members) {
            // Map user details to members
            Promise.all(team.members.map(async (teamUser: any) => {
                let userGameRank = '';
            
                try {
                const res = await axios.get(`${process.env.REACT_APP_URL}/profile/publicProfile/unauthenticated/${teamUser.userId}`);
                userGameRank = res.data.rank.filter((rank: any) => rank.game === team.games)[0].rank;
                } catch (error) {
                console.error('Error fetching user rank:', error);
                }
            
                return {
                id: teamUser.user.id,
                image: teamUser.user.imageUrl,
                field1: teamUser.user.profileName,
                field2: teamUser.userId === teamUser.team.captainId ? 'Team Leader' : 'Member',
                field3: userGameRank,
                object: {...teamUser.user},
                };
            })).then(members => setMembers(members))
                .catch(error => console.error('Error setting members:', error));
            } else {
            setMembers([]);
            }
      }

      const displayProfile = (user: any) => {
        setTeamShown(false);
        setProfileShown(true);
        setLoading(false);
        console.log(user);
      
        setName(user.profileName);
        setDescription(user.description);
        setImage(user.imageUrl);
        setGameRanks(user.rank);
        console.log(user)
        if (user.teams) {
            // Map team details to members
            setMembers(user.teams.map((teamWithUser: any) => ({
                id: teamWithUser.team.id,
                image: teamWithUser.team.imageUrl,
                field1: teamWithUser.team.name,
                field2: gameNames[teamWithUser.team.games as keyof typeof gameNames], // Use the team's description as an example
                field3: '',
                object: {...teamWithUser.team},
            })));
        } else {
            setMembers([]);
        }
      }

      const displayProfileById = (id: string) => {
        setLoading(true);
        axios.get(process.env.REACT_APP_URL + `/profile/publicProfile/unauthenticated/${id}`).then(res => {
          displayProfile(res.data);
          console.log(res.data);
        });
      }

      const displayTeamById = (id: string) => {
        setLoading(true);
        axios.get(process.env.REACT_APP_URL + `/teams/${id}`).then(res => {
            console.log(res.data);
          displayTeam(res.data);
        });
    }


    useEffect(() => {
        const searchPrompt = inputValue === initialInputValue ? '' : inputValue.toLowerCase();
    
        const filteredProfiles = [
            ...all.filter((item: any) => item.profileName && item.profileName.toLowerCase().indexOf(searchPrompt) === 0),
            ...all.filter((item: any) => item.profileName && item.profileName.toLowerCase().indexOf(searchPrompt) > 0),
        ].sort((a: any, b: any) => a.profileName.localeCompare(b.profileName));
    
        const filteredTeams = [
            ...all.filter((item: any) => item.name && item.name.toLowerCase().indexOf(searchPrompt) === 0),
            ...all.filter((item: any) => item.name && item.name.toLowerCase().indexOf(searchPrompt) > 0),
        ].sort((a: any, b: any) => a.name.localeCompare(b.name));
    
        if (displayTeams) {
            setTeams(filteredTeams);
        }
        if (displayProfiles) {
            setProfiles(filteredProfiles);
        }
    }, [inputValue, all]);

    function JoinButton() {
        const [joinError, setJoinError] = useState('');
        // const [userId, setUserId] = useState(localStorage.getItem('userId'));
      
        const sendJoinRequest = async () => {
            // if (!userId) {
            //   alert('Please log in to join a team.');
            //   return;
            // }
        
            try {
                const response = await axios.post(`${process.env.REACT_APP_URL}/join-request/send`, {
                    name: teamId,
                }, {
                    headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token'): ""}`
                    }
                });
        
                console.log(response.data);
                setJoinError("Request sent successfully!");
            } catch (error: any) {
                setJoinError(error.response.data.message || "An error occurred.");
                console.error(error);
            }
          };
      
        return (  
            <>
                {joinError && (
                <p className="text red small text-start pb-2">
                    {joinError}
                </p>
                )}
                {/* <button onClick={() => sendJoinRequest()} className="custom-button p-3 text-left font-weight-bold my-2 white">Request to Join</button> */}
            </>
        );
    }

    const handlePageChange = (pageNumber: number) => {
        setLoading(true);
        axios.get(process.env.REACT_APP_URL + (displayTeams ? "/search/teams" : "/search/users"), {
            params: {
                page: Number(pageNumber),
                pageSize: Number(pagination.pageSize)
            }
        }).then(res => {
            setAll(displayTeams ? res.data.teams : res.data.users);
            setPagination(res.data.pagination);
            setLoading(false);
        });
    };


    // Components
    const SearchBar = () => (
        <>
            <div className="d-flex flex-row m-3 border border-danger search-box">
                <img src={searchLogo} className="p-2" alt='search'/>
                <div className=""></div>
                <input className="bg-transparent border-0 outline-0 text-search bolder-text"
                       value={inputValue}
                       type="text"
                       onClick={() => inputValue === initialInputValue && setInputValue('')}
                       onChange={(event: any) => setInputValue(event.target.value)}
                />
            </div>
        </>
    );

    const SearchButtons = () => (
        <>
            <div className="text-search text-wrapper m-3 mt-0 mb-0">Search in:</div>
            <div className="d-flex flex-row m-3 mt-0 justify-content-between">
                <div className={(displayTeams ? 'selected-button-outline' : 'button-outline') + ' p-1'}>
                    <button className={(displayTeams ? 'selected-button border border-danger' : 'border-0 outline-0') + ' w-100 text-search bolder-text bigger-text button p-1'}
                            onClick={searchTeams}
                    >
                        Teams
                    </button>
                </div>
                <div className={(displayProfiles ? 'selected-button-outline' : 'button-outline') + ' p-1'}>
                    <button className={(displayProfiles ? 'selected-button border border-danger' : 'border-0 outline-0') + ' w-100 text-search bolder-text bigger-text button p-1'}
                            onClick={searchProfiles}
                    >
                        Profiles
                    </button>
                </div>
            </div>
        </>
    );

    const SearchResults = () => (
        <>
            {displayTeams &&
            <div>
                <div className="text-search bigger-text text-wrapper m-3 mt-0 mb-0">Teams:</div>
                {teams.map((team: any) =>
                    <div className={(team.id === selectedId ? 'selected-tab' : 'border border-danger') + ' d-flex flex-row justify-content-between mx-3 my-1 text-search p-2'}
                        key={team.id}
                        onClick={() => {setSelectedId(team.id);
                            console.log(team);
                            console.log(team.id);
                            console.log(selectedId);
                        displayTeam(team);
                        }}
                    >
                        <div className={(team.id === selectedId ? 'text-danger medium-text' : '') + ' text-wrapper'}>{team.name}</div>
                        <div className={(team.id === selectedId ? 'text-danger medium-text' : '') + ' text-wrapper'}>{team.numberOfUsers}/{ team.games === "RL" ? 2 : 5}</div>
                        <div className={(team.id === selectedId ? 'text-danger medium-text' : '') + ' text-wrapper'}>{team.games}</div>
                    </div>)}
            </div>}
            {displayProfiles &&
            <div>
                <div className="text-search bigger-text text-wrapper m-3 mt-0 mb-0">Profiles:</div>
                {profiles.map((profile: any) =>
                    <div className={(profile.id === selectedId ? 'selected-tab' : 'border border-danger') + ' d-flex flex-row justify-content-between mx-3 my-1 text-search p-2'}
                        key={profile.id}
                        onClick={() => {
                        setSelectedId(profile.id);
                        displayProfile(profile);
                        }}
                    >
                        <div className={(profile.id === selectedId ? 'text-danger medium-text' : '') + ' text-wrapper'}>{profile.profileName}</div>
                        {profile.teams && profile.teams.map((teamUser: any, index: number) => (
                            <div key={index}>
                                <div>{teamUser.team.games}</div>
                            </div>
                        ))}

                    </div>)}
            </div>}
        </>
    );

    const LeftPanel = () => (
        <>
            {(!isSmall || (isSmall && !teamShown && !profileShown)) && <div className="d-flex flex-column h85 w50 border border-danger blur-bg scrollable">
                {SearchBar()}
                <SearchButtons/>
                <SearchResults/>
                {displayPages && <div className="mt-auto">{PaginationBar()}</div>}
            </div>}
        </>
    );

    const TopSideRightPanel = () => (
        <>
            <div className={`d-flex ${isSmall ? 'flex-column' : 'flex-row'}`}>
                <img src={image} alt={image} className="m-4 profile-img-search profile-img-border-search" />
                {profileShown && <div className={`d-flex flex-column align-content-start mt-4 justify-content-between w-100 ${isSmall ? 'margin-left-small' : ''}`}>
                    <div>
                        <div className="text-search profile-text text-wrapper very-big-text">Profile name</div>
                        <div className="text-search text-wrapper very-big-text">{name}</div>
                    </div>

                    <div style={{ textAlign: 'justify', whiteSpace: 'pre-wrap' }}>
                        <div className="text-search profile-text text-wrapper very-big-text">About</div>
                        <div className="text-search text-wrapper very-big-text" style={{ paddingRight: '3vh'}}>{description}</div>
                    </div>

                    <div className='justify-content-around'>
                        {gameRanks && gameRanks.map((rank: any) => (
                            <div key={rank.id} className='d-flex flex-row p-0 '>
                                <div className="text-search profile-text text-wrapper right-margin very-big-text">{rank.game} RANK</div>
                                <div className="text-search text-wrapper very-big-text">{rank.rank}</div>
                            </div>
                        ))}
                    </div>
                
                </div>}
                {teamShown && <div className={`d-flex flex-column align-content-start mt-2 w-100 justify-content-between ${isSmall ? 'margin-left-small' : ''}`}>
                    <div>
                        <div className="text-search profile-text text-wrapper very-big-text">Team name</div>
                        <div className="text-search text-wrapper very-big-text">{name}</div>
                    </div>
                    <div>
                        <div className="text-search profile-text text-wrapper mt-3 very-big-text">Competition</div>
                        <div className="text-search text-wrapper bigger-text very-big-text">{competition}</div>
                    </div>
                    <div style={{ textAlign: 'justify', whiteSpace: 'pre-wrap' }}>
                        <div className="text-search profile-text text-wrapper very-big-text">About</div>
                        <div className="text-search text-wrapper very-big-text" style={{ paddingRight: '3vh'}}>{description}</div>
                    </div>
                    {/* { isRecruting && isLoggedIn && <div>
                        <div className="text-search profile-text text-wrapper">This team is recruting!</div>
                        <div className='text-wrapper'>
                            <JoinButton/>
                        </div>
                    </div>} */}
                </div>}
            </div>
        </>
    );

    const Members = () => (
        <>
            {members.map((member: any) =>
                <div key={member.id}>
                    <div className="d-flex flex-row justify-content-start">
                        {!isSmall && <img src={member.image} alt={member.image} className="mx-4 profile-img-small-search profile-img-small-border-search" />}
                        {isSmall && <div className="d-flex flex-column">
                            <img src={imageMargin} alt={imageMargin} className="mx-4 image-margin" />
                            <img src={member.image} alt={member.image} className="mx-4 mt-2 mb-2 profile-img-small-search profile-img-small-border-search" />
                            <img src={imageMargin} alt={imageMargin} className="mx-4 image-margin" />
                        </div>}
                        <div className={(isSmall ? '' : 'mx-4 px-4')+ ' d-flex flex-row justify-content-between member-border p-1 position-relative'}>
                            <div className="d-flex flex-column justify-content-around">
                                {!isSmall && <div className="d-flex flex-row">
                                <div className="w-45">
                                    <div className="text-search text-wrapper">{teamShown ? '' : 'Team '}Name</div>
                                </div>
                                <div className="mx-5 text-search text-danger text-wrapper ellipsis">{member.field1}</div>
                                </div>}
                                {isSmall && <div className="d-flex flex-column">
                                <div className="text-search text-wrapper">{teamShown ? '' : 'Team '}Name</div>
                                <div className="text-search text-danger text-wrapper no-space ellipsis">{member.field1}</div>
                                </div>}

                                {!isSmall && <div className="d-flex flex-row">
                                <div className="w-45">
                                    <div className="text-search text-wrapper">{teamShown ? 'Role' : 'Competition'}</div>
                                </div>
                                <div className="mx-5 text-search text-danger text-wrapper ellipsis">{member.field2}</div>
                                </div>}
                                {isSmall && <div className="d-flex flex-column">
                                <div className="text-search text-wrapper">{teamShown ? 'Role' : 'Competition'}</div>
                                <div className="text-search text-danger text-wrapper no-space ellipsis">{member.field2}</div>
                                </div>}
                            </div>
                            <div className="right-image"/>
                        </div>
                    </div>
                    <div className="d-flex flex-row mx-4 justify-content-end mb-2 text-button"
                         onClick={teamShown ? () => {displayProfileById(member.id)} : () => {displayTeamById(member.id)}}
                    >
                        <img src={danger} alt={danger} className="mx-2"/>
                        <div className="text-search text-danger">Click to go to {teamShown ? 'profile' : 'team'}</div>
                    </div>
                </div>
            )}
        </>
    );

    const RightPanel = () => (
        <>
            {(teamShown || profileShown) &&
            <div className="d-flex flex-column">
                {isSmall && <div className="d-flex flex-row justify-content-end mb-2 text-button"
                                  onClick={() => {setTeamShown(false); setProfileShown(false); setSelectedId('');}}
                >
                    <img src={backButton} alt={backButton} className="mx-1"/>
                    <div className="text white">Back to search</div>
                </div>}
                <div className="d-flex flex-column h85 w100 border border-danger blur-bg scrollable">
                    <TopSideRightPanel/>
                    <div className="text-search bigger-text text-wrapper m-4 mb-0">{name}'s {teamShown ? 'Members' : 'Teams'}</div>
                    <Members/>
                    {profileShown && gameRanks.map((rank: any, index: number) => (
                        <div key={index} className="m-3 mt-0 mb-0">
                            <LinkCard rank={rank} />
                        </div>
                        ))}
                    
                </div>
            </div>
            }
        </>
    );

    const PaginationBar = () => {
        const handleItemsPerPageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
            const newPageSize = event.target.value as number;
            setLoading(true);
            axios.get(process.env.REACT_APP_URL + (displayTeams ? "/search/teams" : "/search/users"), {
                params: {
                    page: pagination.currentPage,
                    pageSize: newPageSize
                }
            }).then(res => {
                setAll(displayTeams ? res.data.teams : res.data.users);
                setPagination(res.data.pagination);
                setLoading(false);
            });
        };
    
        return (
            <div className='d-flex align-items-center m-3 mt-0'>
                <div className='d-flex justify-content-center flex-grow-1'>
                    <button className="w-20 text-search bolder-text bigger-text button p-1 no-border"
                        onClick={() => handlePageChange(pagination.currentPage - 1)}
                        disabled={pagination.currentPage === 1}
                    >
                        Previous
                    </button>
                    <span className='text-search bolder-text ml-3 mr-3 page-number'>Page {pagination.currentPage} of {pagination.totalPages}</span>
                    <button className="w-20 text-search bolder-text bigger-text button p-1 no-border"
                        onClick={() => handlePageChange(pagination.currentPage + 1)}
                        disabled={pagination.currentPage === pagination.totalPages}
                    >
                        Next
                    </button>
                </div>
                <select value={pagination.pageSize} onChange={handleItemsPerPageChange} className='ml-auto custom-dropdown'>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={displayProfiles ? totalUsers : totalTeams}>All</option>
                </select>
            </div>
        );
    };

    return (
        <div className={(loading ? 'loading' : '') + (isSmall ? ' flex-column align-items-center' : ' flex-row justify-content-center') + ' d-flex mt-3 search-page'}>
            {LeftPanel()}
            {!isSmall && (teamShown || profileShown) && <div className="w5"/>}
            <RightPanel/>
        </div>
    );
}

export default Search;
