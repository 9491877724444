import React from "react";
import Logo from "./Logo";

interface Props {
  logo: string;
  type: string;
  array: Array<Data>;
}

interface Data {
  name: string;
  path: string;
  description: string;
}

export default function Category(props: Props) {
  const isTwoLogos = props.array.length === 2;
  
  return (
    <div className="sponsors-container">
      <div className="sponsors-content">
        <img src={props.logo} alt="logo" />
        <h1 className="text">
          {props.type === "Premium" ? (
            <span className="red w700">{props.type} &nbsp;</span>
          ) : props.type === "Gold" ? (
            <span className="gold w700">{props.type} &nbsp;</span>
          ) : (
            <span className="text-secondary w700">{props.type} &nbsp;</span>
          )}
          <span className="white w700">Sponsor</span>{" "}
        </h1>
      </div>
      <div className={`sponsors-logos ${isTwoLogos ? 'two-logos' : ''}`}>
        {props.array.map((icon, i) => {
          return <Logo key={i} path={icon.path} className="logo" />;
        })}
      </div>
    </div>
  );
}
