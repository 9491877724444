import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CustomButton from '../../common/CustomButton';
import axios from 'axios';
import * as Yup from 'yup';
import './Register.css';

function Register() {
  const nav = useNavigate();
  const [registerError, setRegisterError] = useState('');

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required('First name missing'),
        lastName: Yup.string().required('Last name missing'),
        email: Yup.string()
          .email('Invalid email')
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            'Email is invalid',
          )
          .required('Email missing'),
        phone: Yup.string()
          .required('Phone number missing')
          .matches(RegExp('^(07)[0-9]{8}$'), 'Invalid phone number'),
        profileName: Yup.string().required('Profile name missing'),
        password: Yup.string()
          .min(6, 'Password is invalid')
          .required('Password missing'),
        confirmPass: Yup.string()
          .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
          .required('Confirm Password missing'),
      }),
    [],
  );

  const handleRegistration = async (
    values: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      profileName: string;
      password: string;
      confirmPass: string;
    },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    try {
      const registerResponse = await registerUser(values);
      setSubmitting(false);
      nav('/login');
    } catch (error: any) {
      setRegisterError(error.message);
      setSubmitting(false);
    }
  };

  const registerUser = async (values: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    profileName: string;
    password: string;
    confirmPass: string;
  }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/register`,
        values,
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  };

  return (
    <Container>
      <div className="align-items-center border border-margin border-danger px-md-5 py-3">
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            profileName: '',
            password: '',
            confirmPass: '',
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) =>
            handleRegistration(values, { setSubmitting })
          }
        >
          {(formik) => (
            <div className="ps-3 pe-3 pb-2 justify-content-around">
              <p className="text text-center white large justify">Register</p>
              <Form>
                <Row className="mb-4 justify-content-around">
                  <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                    <p className="ms-2 text white medium justify">First Name</p>
                    <Field
                      className="autofill p-1 border-margin border-danger text white small transparent-background large-field"
                      type="text"
                      name="firstName"
                    />
                    <ErrorMessage name="firstName">
                      {(msg) => (
                        <p className="ms-2 text red small justify">{msg}</p>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
                <Row className="mb-4 justify-content-around">
                  <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                    <p className="ms-2 text white medium justify">Last Name</p>
                    <Field
                      className="autofill p-1 border-margin border-danger text white small transparent-background large-field"
                      type="text"
                      name="lastName"
                    />
                    <ErrorMessage name="lastName">
                      {(msg) => (
                        <p className="ms-2 text red small justify">{msg}</p>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
                <Row className="mb-4 justify-content-around">
                  <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                    <p className="ms-2 text white medium justify">Email</p>
                    <Field
                      className="autofill p-1 border-margin border-danger text white small transparent-background large-field"
                      type="email"
                      name="email"
                    />
                    <ErrorMessage name="email">
                      {(msg) => (
                        <p className="ms-2 text red small justify">{msg}</p>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
                <Row className="mb-4 justify-content-around">
                  <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                    <p className="ms-2 text white medium justify">
                      Phone Number
                    </p>
                    <Field
                      className="autofill p-1 border-margin border-danger text white small transparent-background large-field"
                      type="text"
                      name="phone"
                    />
                    <ErrorMessage name="phone">
                      {(msg) => (
                        <p className="ms-2 text red small justify">{msg}</p>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
                <Row className="mb-4 justify-content-around">
                  <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                    <p className="ms-2 text white medium justify">
                      Profile Name
                    </p>
                    <Field
                      className="autofill p-1 border-margin border-danger text white small transparent-background large-field"
                      type="text"
                      name="profileName"
                    />
                    <ErrorMessage name="profileName">
                      {(msg) => (
                        <p className="ms-2 text red small justify">{msg}</p>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
                <Row className="mb-4 justify-content-around">
                  <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                    <p className="ms-2 text white medium justify">Password</p>
                    <Field
                      className="autofill p-1 border-margin border-danger text white small transparent-background large-field"
                      type="password"
                      name="password"
                    />
                    <ErrorMessage name="password">
                      {(msg) => (
                        <p className="ms-2 text red small justify">{msg}</p>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
                <Row className="mb-4 justify-content-around">
                  <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                    <p className="ms-2 text white medium justify">
                      Confirm Password
                    </p>
                    <Field
                      className="autofill p-1 border-margin border-danger text white small transparent-background large-field"
                      type="password"
                      name="confirmPass"
                    />
                    <ErrorMessage name="confirmPass">
                      {(msg) => (
                        <p className="ms-2 text red small justify">{msg}</p>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
                {registerError && (
                  <p className="text red medium text-center pb-2">
                    {registerError}
                  </p>
                )}
                <CustomButton>
                  <p className="text white medium justified mx-5">Register</p>
                </CustomButton>
              </Form>
              <div className="split-two pt-3">
                <p className="text white font-weight-normal small inline">
                  Already have an account?{' '}
                </p>
                <a
                  href="/login"
                  className="text white small-medium w700 no-under"
                >
                  Log in
                </a>
              </div>
              <Row className="pt-3">
                <Col className="d-flex justify-content-center">
                  <p className="text white fw-light inline small-text">
                    After registration complete your profile with in-game
                    usernames, ranks and contact info then join a team.
                  </p>
                </Col>
              </Row>
            </div>
          )}
        </Formik>
      </div>
    </Container>
  );
}

export default Register;
