import "./Timer.css";
import { Button, Container, Image } from "react-bootstrap";
import { useState, useEffect } from "react";
import buton from "./Buton.png";
import pacanea from "./Pacanea.png";
import { useNavigate } from "react-router-dom";

function Timer() {
  const [days, setDays] = useState("00");
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [displayNow, setDisplayNow] = useState(true);

  const navigate = useNavigate();

  function getFormatted(time: number) {
    return ("0" + time).slice(-2);
  }

  function getTime(deadline: string) {
    const time = Date.parse(deadline) - Date.now();

    if (time < 0) {
      return;
    }

    setDisplayNow(false);
    setDays(getFormatted(Math.floor(time / (1000 * 60 * 60 * 24))));
    setHours(getFormatted(Math.floor((time / (1000 * 60 * 60)) % 24)));
    setMinutes(getFormatted(Math.floor((time / (1000 * 60)) % 60)));
  }

  useEffect(() => {
    const interval = setInterval(
      () => getTime(process.env.REACT_APP_DEADLINE),
      1000
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Container className="box mt-5 mb-5 p-0">
        <Container className="position-relative p-0 m-0">
          <Image src={pacanea} alt="LANParty" className="size" />
          <Container className="timer p-0 m-0">
            <div className="mb-2">
              <p className="text-arcade white large-timer inline ">{days}</p>
              <p className="text-over white large-timer inline"> : </p>
              <p className="text-arcade white large-timer inline ">{hours}</p>
              <p className="text-over white large-timer inline"> : </p>
              <p className="text-arcade white large-timer inline ">{minutes}</p>
            </div>

            <div className="mb-5">
              <p className="text-over white small-timer inline">Days&nbsp; </p>
              <p className="text-over white small-timer inline">Hours&nbsp; </p>
              <p className="text-over white small-timer inline">Minutes</p>
            </div>

            <Button
              className="bg-transparent border-0"
              onClick={() => {
                navigate("/register");
              }}
            >
              <Image src={buton} alt="Buton" className="buton" />
            </Button>
          </Container>
        </Container>

        <Container className="info text-white p-0">
          <div className="text">
            <h1>
              <span className="text-danger">LAN</span>Party 2024
            </h1>
            <h3>23-24 Martie</h3>
            <h3>Hol EC</h3>

            <h4>LOL, CS 2, Rocket League</h4>
            <h4>Side competitions and more</h4>
          </div>
        </Container>
      </Container>
    </>
  );
}

export default Timer;
