import React from "react";

interface LogoProps {
    path: string;
    className?: string;
}

const Logo: React.FC<LogoProps> = (props: LogoProps) => {
    const { path, className } = props;
    return <img className={className} src={path} alt={path} />;
};

export default Logo;
