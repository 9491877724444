import { Formik, Form, Field, ErrorMessage } from "formik";
import DragAndDrop from "../../../common/DragAndDrop/DragAndDrop";

import video from "../../../images/video.png";
import CompetitionSelector from "../../../common/CompetitionSelector/CompetitionSelector";
import CustomButton from "../../../common/CustomButton";
import axios from "axios";
import * as Yup from "yup";
import { useRef } from "react";

function CreateTeam() {
  const token = localStorage.getItem("token");
  const tournamentMap: { [key: string]: string } = {
    "1": "LOL",
    "2": "CS",
    "3": "RL",
  };

  return (
    <div className="px-md-3 pt-4 text-start">
      <Formik
        initialValues={{
          tournaments_id: "1",
          name: "",
          description: "",
          teamImage: video,
        }}
        validationSchema={Yup.object().shape({
          tournaments_id: Yup.number()
            .required("Please select a tournament")
            .min(1)
            .max(4),
          name: Yup.string().max(50).required("Name missing"),
          teamImage: Yup.mixed().test(
            "size",
            "Image size cannot exceed 1Mb!",
            (val: any) => {
              return !val || !val?.size || val?.size < 1_000_000;
            }
          ),
          description: Yup.string().max(5000),
        })}
        enableReinitialize
        onSubmit={(values, { setSubmitting, setErrors }) => {
          let form = new FormData();
          for (let [key, value] of Object.entries(values)) {
            if (key == "teamImage" && value) form.append("image", value);
            else if (key == "tournaments_id" && value)
              form.append("games", tournamentMap[value]);
            else if (value) form.append(key, value);
          }

          if (values.teamImage == video) {
            form.delete("image");
          }

          axios
            .post(`${process.env.REACT_APP_URL}/teams`, form, {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${token}`,
              },
            })
            .then(() => {
              setErrors({ tournaments_id: "Successfully created team!" });
              setSubmitting(false);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            })
            .catch((e) => {
              console.log(e);
              let nameErr =
                e.response.data.errors?.name != null
                  ? e.response.data.errors?.name[0]
                  : "";
              let teamErr =
                e.response.data.errors?.team != null
                  ? e.response.data.errors?.team[0]
                  : "";
              let error = e.response.data.statusCode >= 400 ? e.response.data.message : "";
              console.log(error)
              if (error) {
                console.log(error)
                setErrors({ tournaments_id: error });
                setSubmitting(false);

              } else
                setErrors({ tournaments_id: nameErr + teamErr });
              setSubmitting(false);
            });
        }}
      >
        {({ errors }) => (
          <Form className="text-center">
            <p className="text white text-start medium">Registration is closed</p>
            {/* <Field name="tournaments_id" component={CompetitionSelector} />
            <p className="text white text-start medium">Team Name</p>
            <Field
              className="autofill mb-3 ps-3 py-2 w-100 blur-bg border border-danger text white small-medium transparent-background"
              type="text"
              name="name"
              placeholder="Insert team name here..."
            />
            <ErrorMessage name="name">
              {(msg) => <p className="ms-2 text red small justify">{msg}</p>}
            </ErrorMessage>

            <p className="text white text-start medium">Choose team logo</p>
            <Field
              name="teamImage"
              component={DragAndDrop}
              accept="image/*"
              margin="normal"
            />
            <ErrorMessage name="teamImage">
              {(msg) => <p className="ms-2 text red small justify">{msg}</p>}
            </ErrorMessage>

            <p className="text white text-start medium">About team</p>
            <Field
              className="autofill mb-3 ps-3 pt-2 pb-2 w-100 blur-bg border border-danger text white small-medium transparent-background message-box"
              type="text"
              name="description"
              placeholder="Write your description here"
              as="textarea"
              rows="6"
            />
            <ErrorMessage name="description">
              {(msg) => <p className="ms-2 text red small justify">{msg}</p>}
            </ErrorMessage>

            {errors && (
              <p className="text red medium text-center">{errors.teamImage}</p>
            )}
            {errors && (
              <p className="text red medium text-center">
                {errors.tournaments_id}
              </p>
            )}
            <div className="pb-2" />

            <CustomButton>
              <p className="text white medium justified">Create Team</p>
            </CustomButton>
            <div className="pb-md-3" /> */}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateTeam;
