import "./Sponsors.css";
import Category from "./Category";
import Premium from "../../images/premium_badge.png";
import Gold from "../../images/gold.png";
import Silver from "../../images/silver.png";
import Bronze from "../../images/bronze.png";
import { Container, Row } from "react-bootstrap";
import { sponsors } from "./data";
import top_decoration from "../../images/top_decoration.png";

export default function Sponsors() {
  return (
    <>
      {" "}
      <img
        className="py-5 separator"
        src={top_decoration}
        alt={top_decoration}
      />
      <Row className="mt-4">
        <Container className="sponsor" id="sponsors">
          <Category logo={Premium} type="Premium" array={sponsors.Diamond} />
          <Category logo={Gold} type="Gold" array={sponsors.Platinum} />
          <Category logo={Silver} type="Silver" array={sponsors.Gold} />
          <Category logo={Bronze} type="Bronze" array={sponsors.Silver} />
          {/* <p className="text white large">Sponsors coming soon!</p> */}
        </Container>
      </Row>
    </>
  );
}
