import axios from 'axios';
import { Image, Row, Col } from 'react-bootstrap';
import CustomButton from '../../../../common/CustomButton';
import { TeamInterface, TeamMateInterface } from '../../Interfaces';
import './TeamCard.css';
import { useEffect, useState } from 'react';
import detail from '../../../../images/Detail.png';
import detailVertical from '../../../../images/image-margin.png';
import Collapse from 'react-bootstrap/Collapse';

interface Props {
    team: TeamInterface,
}

function TeamCard({ team }: Props) {
    const token = localStorage.getItem("token");
    const [captain, setCaptain] = useState("");
    const [users, setUsers] = useState(Array<TeamMateInterface>);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        axios({
            method: "get",
            url: process.env.REACT_APP_URL + "/profile/teams/captain/name/" + team.captainId,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            setCaptain(res.data.profileName)

        }).catch(e => {
            console.log(e);
        })

        axios({
            method: "get",
            url: process.env.REACT_APP_URL + "/profile/teams/members/" + team.id,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            setUsers(res.data.map((user: any) => {
                return {
                    name: user.profileName,
                    type: "USER",
                    id: user.id
                }
            }))
        }).catch(e => {
            console.log(e);
        })
        
    }, [])

    function onClickEliminate(id: string) {
        axios({
            method: "patch",
            url: process.env.REACT_APP_URL + "/teams/eliminate/" + id + "/" + team.id,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            setUsers(users.filter((user) => user.id !== id))
        }).catch(e => {
            setError(e.response.data.message)
        })
    }

    return (
        <>
            <Row>
                <Col xl={2} lg={3} md={3} sm={3} xs={4} className='my-auto'>
                    {
                        window.matchMedia("(max-width: 800px)").matches ? <Image src={detailVertical} alt={detailVertical}></Image> : <div></div>
                    }
                    <Image src={team.image} alt={team.image} className='h10 w10 profile-img-border profileImg' />
                    {
                        window.matchMedia("(max-width: 800px)").matches ? <Image src={detailVertical} alt={detailVertical}></Image> : <div></div>
                    }
                </Col>
                <Col xl={10} lg={9} md={9} sm={9} xs={8} className='border border-danger text-start p-0 m-0'>
                    <Row className='container-team m-0 p-0'>
                        <Col xs={11} className='pt-2 pb-2'>
                            <Row>
                                <Col xl={4} lg={6} md={3} sm={12} xs={12}>
                                    <p className='text white small'>Team Name</p>
                                </Col>
                                <Col xl={8} lg={6} md={9} sm={12} xs={12}>
                                    <p className='text red small text-truncate'>{team.name}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} lg={6} md={3} sm={12} xs={12}>
                                    <p className='text white small'>Competition</p>
                                </Col>
                                <Col xl={8} lg={6} md={9} sm={12} xs={9}>
                                    <p className='text red small'>{team.competition}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} lg={6} md={3} sm={12} xs={12}>
                                    <p className='text white small'>Join Token</p>
                                </Col>
                                <Col xl={8} lg={6} md={9} sm={12} xs={12}>
                                    <p className='text red small text-truncate'>Registration closed</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={4} lg={6} md={3} sm={12} xs={12}>
                                    <p className='text white small'>Team Leader</p>
                                </Col>
                                <Col xl={8} lg={6} md={9} sm={12} xs={12}>
                                    <p className='text red small text-truncate'>{captain}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1} className='p-0 m-0'>
                            {
                                window.matchMedia("(min-width: 800px)").matches ? <Image src={detail} alt={detail} className='float-end detailImg'></Image> : <div></div>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className='pb-3'>
                <p className='text red small text-truncate text-end clickable' onClick={() => setOpen(!open)} >Click to see members</p>
                <Collapse in={open}>
                    <div>
                    {users.map((t, idx) => (
                        <div key={idx}>
                            <TeamMate type={t.type} name={t.name} id={t.id} />
                            {/* <p className='text red small text-truncate text-end clickable' onClick={() => onClickEliminate(t.id)}>Eliminate player</p>
                            {error && <p className='text red small text-center'>{error}</p>} */}
                        </div>
                    ))}
                    </div>
                </Collapse>
            </div>
        </>
    );
}

function TeamMate({ id, type, name }: TeamMateInterface) {
    return (
        <div className='drop-section'>
            <Row className='border border-danger text-start teammate-row'>
                <Col xl={3} lg={5} md={4} sm={6} xs={6} className='my-auto h-100'>
                    <p className='text white small-medium'>{type}</p>
                </Col>
                <Col xl={9} lg={7} md={8} sm={6} xs={6} className='my-auto'>
                    <p className='text white small-medium'>{name}</p>
                </Col>
            </Row>
        </div>
    );
}

export default TeamCard;