import { Container, Col, Row } from "react-bootstrap";
import "./PastEditions.css";
import top_decoration from "../../images/top_decoration.png";
import pastEdition1 from "./../../images/2022.jpeg";
import pastEdition2 from "./../../images/2023.jpeg";
import edition from "../../images/edition.png";

function PastEditions() {
  return (
    <Container fluid className="pad" id="about">
      <img
        className="py-5 separator"
        src={top_decoration}
        alt={top_decoration}
      />
      <Row className="border-image">
        <Col
          className="edition-col pe-md-5 mb-5"
          xl={8}
          lg={6}
          md={12}
          sm={12}
          xs={12}
        >
          <div>
            <h1 className="red w700" style={{ paddingTop: '2vh' }}>Past editions </h1>
            <p className="text white medium justify" style={{ textAlign: "justify", paddingBottom: '2vh'}}>
              Privire retrospectivă asupra edițiilor anterioare:
            </p>
            <Container className="edition">
              <img src={pastEdition1} alt="video" className="past-edition" />
              <div className="inline">
                <h4 className="red w700">2023 Edition</h4>
                <p className="text-about" style={{ textAlign: "justify"}}>
                  În fiecare an, evenimentul LAN Party este îmbunătățit, iar la această ediție au fost organizate patru competiții principale: Counter-Strike: Global Offensive, Rocket League, League of Legends și Minecraft Hunger Games. Anul acesta ne-am întors în formatul fizic, competiția de CS:GO având un număr de 17 echipe, cea de Rocket League 12 echipe, cea de League of Legends un număr de 25 echipe, iar Minecraft Hunger Games 8 participanți. Astfel, am putut simți emoțiile și spiritul competitiv chiar de lângă prietenii și colegii noștri ce participau la diferitele competiții!
                </p>
                <p className="text-about" style={{ textAlign: "justify"}}>
                  Atmosfera din holul Facultății de Automatică și Calculatoare a fost plină de energie, iar voluntarii și susținătorii au avut oportunitatea de a se bucura de activitățile extra organizate pe lângă cele patru competiții principale.
                </p>
                <p className="text-about" style={{ textAlign: "justify"}}>
                  Experiențele VR, competițiile de Mortal Kombat, Just Dance și FIFA au fost îndrăgite de participanți, iar jocurile de societate din zona de relaxare au oferit un prilej excelent pentru socializare și distracție.
                </p>
              </div>
            </Container>
          </div>
          

          <Container className="edition">
            <img src={pastEdition2} alt="video" className="past-edition" />
            <div className="inline">
              <h4 className="red w700">2022 Edition</h4>
              <p className="text-about" style={{ textAlign: "justify"}}>
                <b>Etapa locală: </b>Deși competițiile principale s-au desfășurat în format online, atunci când nu au avut de monitorizat sau comentat un meci, voluntarii noștri s-au întâlnit în format fizic pentru a socializa și a se juca pe consolele oferite de către cei de la Mancave. Astfel, am reușit să ne aducem aminte de o mică părticică din atmosfera în format fizic a acestui eveniment.
              </p>
              <p className="text-about" style={{ textAlign: "justify"}}>
                <b>Etapa națională: </b>Cea de-a doua etapă a reprezentat revenirea la formatul fizic al evenimentului. Atât studenții din facultatea noastră, cât și studenții din alte centre universitare din țară, s-au întâlnit în biblioteca UPB pentru a socializa și a se juca pe consolele și jocurile de societate din zona de relaxare. Tot în această ediție, aceștia au avut ocazia de a trăi noi experiențe în simulatorul de Formula1, pus la dispoziție de către cei de la Redbull.
              </p>
              <p className="text-about" style={{ textAlign: "justify"}}>
                Așa cum ne-am obișnuit, aflat într-o continuă dezvoltare, evenimentul LAN Party s-a autodepășit și în acest an, fiind organizate trei competiții oficiale: Rocket League, League of Legends și Counter Strike: Global Offensive.
              </p>
            </div>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default PastEditions;
