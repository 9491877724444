import { Container, Col, Row } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CustomButton from "../../../common/CustomButton";
import { useEffect, useState } from "react";
import axios from "axios";
import * as Yup from 'yup';
import { TeamInterface, UserInterface } from "../Interfaces";
import DragAndDrop from "../../../common/DragAndDrop/DragAndDrop";
import './ProfileEdit.css';

interface Props {
    user: UserInterface,
    teams: Array<TeamInterface>
};

function ProfileEdit({ user, teams }: Props) {
    const token = localStorage.getItem('token');
    const [formErrors, setFormErrors] = useState("");
    const phoneNumberRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/gm;

    const games = [{ "name": "League of Legends", "short": "LOL" },
    { "name": "Counter-Strike 2", "short": "CS" },
    { "name": "Rocket League", "short": "RL" }];

    let gameranksLOL = user?.ranks.find(x => x.game === "LOL");
    let gameranksCS = user?.ranks.find(x => x.game === "CS");
    let gameranksRL = user?.ranks.find(x => x.game === "RL");

    return (
        <div className='px-md-3 pt-4 pb-3'>
            <Formik
                initialValues={{
                    profileName: user?.profileName,
                    phone: user?.phone,
                    description: user?.description,
                    image: user?.image,
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    gameranks: [
                        {
                            rank: gameranksLOL?.rank,
                            link: gameranksLOL?.link,
                            gameUsername: gameranksLOL?.gameUsername,
                            games: "LOL"
                        },
                        {
                            rank: gameranksCS?.rank,
                            link: gameranksCS?.link,
                            gameUsername: gameranksCS?.gameUsername,
                            games: "CS"
                        },
                        {
                            rank: gameranksRL?.rank,
                            link: gameranksRL?.link,
                            gameUsername: gameranksRL?.gameUsername,
                            games: "RL"
                        }
                    ]
                }}
                validationSchema={Yup.object().shape({
                    profileName: Yup.string().max(50).required("Name missing"),
                    description: Yup.string()
                        .max(5000)
                        .test(
                            'character-limit',
                            'Description should be 500 characters or less',
                            (value) => !value || value.length <= 500
                        )
                        .required('Description is missing'),
                    image: Yup.mixed().test(
                        "size",
                        "Image size cannot exceed 1Mb!",
                        (val: any) => {
                            console.log(val?.size)
                            return !val || !val?.size || val?.size < 1_000_000
                        }
                    ).required("Image is missing!"),
                    firstName: Yup.string().required("First name missing"),
                    lastName: Yup.string().required("Last name missing"),
                    phone: Yup.string().required("Phone number missing").matches(phoneNumberRegExp, "Invalid phone number"),
                })}
                enableReinitialize
                onSubmit={(values, { setSubmitting, setErrors }) => {
                    console.log(values);

                    axios.patch(process.env.REACT_APP_URL + "/profile/myProfile", values, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            "Authorization": `Bearer ${token}`
                        }
                    }).then(() => {
                        setFormErrors("Successfully edited profile");
                        setSubmitting(false);
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }).catch(e => {
                        setFormErrors(e.response.data.message)
                    })
                }}
            >
                {({ errors }) => (
                    <Form>
                        <Row className="p-0 m-0">
                            <Col>
                                <p className="text white medium justify">Profile Name</p>
                                <Field className="autofill mb-3 ps-3 pt-2 pb-2 w-100 blur-bg border border-danger text white small-medium transparent-background"
                                    type="text"
                                    name="profileName"
                                    placeholder="Name" />
                                <ErrorMessage name="profileName">{msg => <p className="ms-2 text red small justify">{msg}</p>}</ErrorMessage>
                            </Col>
                        </Row>

                        <Row className="p-0 m-0">
                            <Col>
                                <p className="text white medium justify">About You</p>
                                <Field className="autofill mb-3 ps-3 pt-2 pb-2 w-100 blur-bg border border-danger text white small-medium transparent-background message-box"
                                    type="text"
                                    name="description"
                                    placeholder="About You"
                                    as="textarea"
                                    rows="6" />
                            </Col>
                            <Field
                                name="image"
                                component={DragAndDrop}
                                accept="image/*"
                                margin="normal"
                            />
                        </Row>

                        <p className="text white medium-large justify pb-3">Game Data</p>
                        {games.map((game, idx) => (
                            <div key={idx}>
                                <p className="text red medium justify">{game.name}</p>
                                <Row className="p-0 m-0">
                                    <Col>
                                        <Row>
                                            <Col>
                                                <p className="text white medium justify">Ingame Name</p>
                                                <Field className="autofill ps-3 pt-2 pb-2 w-100 blur-bg border border-danger text white small-medium transparent-background"
                                                    type="text"
                                                    name={"gameranks[" + idx + "].gameUsername"}
                                                    placeholder="Ingame Name" />
                                                <ErrorMessage name={"gameranks[" + idx + "].gameUsername"}>{msg => <p className="ms-2 text red small justify">{msg}</p>}</ErrorMessage>
                                            </Col>
                                            <Col>
                                                <p className="text white medium justify">Rank</p>
                                                <Field className="autofill ps-3 pt-2 pb-2 w-100 blur-bg border border-danger text white small-medium transparent-background"
                                                    type="text"
                                                    name={"gameranks[" + idx + "].rank"}
                                                    placeholder="Rank" />
                                                <ErrorMessage name={"gameranks[" + idx + "].rank"}>{msg => <p className="ms-2 text red small justify">{msg}</p>}</ErrorMessage>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p className="text white medium justify">Profile Link</p>
                                                <Field className="autofill ps-3 pt-2 pb-2 w-100 blur-bg border border-danger text white small-medium transparent-background"
                                                    type="text"
                                                    name={"gameranks[" + idx + "].link"}
                                                    placeholder="Link" />
                                                <ErrorMessage name={"gameranks[" + idx + "].link"}>{msg => <p className="ms-2 text red small justify">{msg}</p>}</ErrorMessage>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        ))}

                        <p className="text white medium-large justify pb-3">Personal Data</p>
                        <Row className="p-0 m-0">
                            <Col>
                                <Row>
                                    <Col>
                                        <p className="text white medium justify">First Name</p>
                                        <Field className="autofill ps-3 pt-2 pb-2 w-100 blur-bg border border-danger text white small-medium transparent-background"
                                            type="text"
                                            name="firstName"
                                            placeholder="First Name" />
                                        <ErrorMessage name="firstName">{msg => <p className="ms-2 text red small justify">{msg}</p>}</ErrorMessage>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className="text white medium justify">Last Name</p>
                                        <Field className="autofill ps-3 pt-2 pb-2 w-100 blur-bg border border-danger text white small-medium transparent-background"
                                            type="text"
                                            name="lastName"
                                            placeholder="Last Name" />
                                        <ErrorMessage name="lastName">{msg => <p className="ms-2 text red small justify">{msg}</p>}</ErrorMessage>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className="text white medium justify">Phone Number</p>
                                        <Field className="autofill ps-3 pt-2 pb-2 w-100 blur-bg border border-danger text white small-medium transparent-background"
                                            type="text"
                                            name="phone"
                                            placeholder="Phone Number" />
                                        <ErrorMessage name="phone">{msg => <p className="ms-2 text red small justify">{msg}</p>}</ErrorMessage>
                                    </Col>
                                 </Row>
                            </Col>
                        </Row>
                        {formErrors && <p className="text red medium text-center">{formErrors}</p>}
                        <ErrorMessage name="description">{msg => <p className="ms-2 text red small justify text-center">{msg}</p>}</ErrorMessage>
                        <ErrorMessage name="image">{msg => <p className="ms-2 text red small justify text-center">{msg}</p>}</ErrorMessage>
                        <div className="mb-3" />
                        <CustomButton><p className='text white medium justified'>Save Changes</p></CustomButton>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default ProfileEdit;