import { Container } from "react-bootstrap";
import Header from "../Header/Header";
import "./Page.css";
import page_end from "../../images/page_end.png";
import leftDetails from "../../images/left_details.png";
import rightDetails from "../../images/right_details.png";
import { useEffect } from "react";
import $ from "jquery";

type Props = {
  children?: React.ReactNode;
};

function Page({ children }: Props) {
  useEffect(() => {
    if (window.location.hash !== null)
      setTimeout(
        () =>
          $("html,body").animate({
            scrollTop: $(window.location.hash).offset()?.top,
          }),
        0.5
      );
  }, []);

  return (
    <>
      <Header />
      <Container fluid className="text-center">
        {children}

        <img src={leftDetails} alt="leftDetails" className="left_details"></img>
        <img
          src={rightDetails}
          alt="rightDetails"
          className="right_details"
        ></img>
        <img src={page_end} alt="page_end" className="page_end"></img>
      </Container>
    </>
  );
}

export default Page;
