import axios from "axios";

interface Participant {
  name: string;
  challongeId: number;
}

const getTournaments = async () => {
  const url = `${process.env.REACT_APP_URL}/tournaments/get/tournaments`;
  return axios.get(url).then((response) => response.data);
};

const getParticipants = async (tournamentId: string) => {
  const url = `${process.env.REACT_APP_URL}/participants/tournament/${tournamentId}`;
  return axios.get(url).then((response) => response.data);
};

export const getLeaderboard = async () => {
  const [{ tournament }] = await getTournaments();

  console.log(tournament);

  const url = `${process.env.REACT_APP_URL}/tournaments/update/wins/${tournament.id}`;

  const participants = await getParticipants(tournament.id);

  console.log(participants);

  const participantsMap: Record<string, string> = {};

  participants.forEach((p: Participant) => {
    const participantName = p.name;
    const participantId = p.challongeId.toString();

    // Salvăm maparea în obiectul participantsMap
    participantsMap[participantId] = participantName;
  });

  const leaderboard = await axios
    .post(
      url,
      { playersMap: participantsMap },
      {
        params: { tournamentId: tournament.id },
      }
    )
    .then((response) => response.data);

  console.log(leaderboard);

  return leaderboard;
};
