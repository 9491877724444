import { Container, Col, Row } from "react-bootstrap";
import "./About.css";
import top_decoration from "../../images/top_decoration.png";

function About() {
  return (
    <Container fluid className="pad" id="about">
      <img
        className="py-5 separator"
        src={top_decoration}
        alt={top_decoration}
      />
      <Row className="border-image">
        <Col
          className="special-col pe-md-5 mb-5"
          xl={8}
          lg={6}
          md={12}
          sm={12}
          xs={12}
        >
          <div>
            <div className="split-two">
              <p className="text white large inline">What is </p>
              <p className="text red large inline">LAN</p>
              <p className="text white large inline">Party</p>
            </div>
            <p className="text red small-medium justify" style={{ textAlign: "justify"}}>
            Cea mai mare competiție de gaming dedicată studenților revine în această primăvară! Te aşteptăm să petreci un weekend plin de energie, distracție și competiție alături de prietenii tăi și de LSAC! Haide să demonstrezi că faci parte din cea mai bună echipă și să cunoști alți studenți pasionați de jocuri video! Premiile sunt pregătite, tu trebuie doar să intrii în meci și să câștigi!
            </p>
          </div>
          <br />

          <p className="text-about" style={{ textAlign: "justify"}}>
            Competitile de League of Legends și de Rocket League vor marca startul evenimentului, având loc sâmbătă, pe 23 martie. Echipele care vor să își demonstreze abilitățile la Counter-Strike 2 sau EA Sports FC 24 au oportunitatea să câștige numeroase premii în cea de-a doua zi, duminică, pe 24 martie.
          </p>
          <p className="text-about" style={{ textAlign: "justify"}}>
            Competiția continuă și pe platforma Twitch, unde veți putea urmări echipele preferate!
          </p>
          <p className="text-about" style={{ textAlign: "justify"}}>
            Orice student își poate forma o echipă la oricare dintre competițiile noastre:
          </p>
          <p className="text-about" style={{ textAlign: "justify"}}>
            <ul>
              <li>LOL (5 membri)</li>
              <li>CS2 (5 membri)</li>
              <li>Rocket League (2 membri)</li>
              <li>EA Sports FC 24 (1 membru)</li>
            </ul>
          </p>
          <p className="text-about" style={{ textAlign: "justify"}}>
            Pentru competiția de EA Sports FC 24 înscrierile se vor face fizic, în ziua evenimentului.
          </p>
        </Col>
        <Col className="special-col mb-5" xl={4} lg={6} md={12} sm={12} xs={12}>
          <iframe className="embed-responsive-item" height="315" src="https://www.youtube.com/embed/DpjzMkB17R0" allow="fullscreen"></iframe>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
