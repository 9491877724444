import { Container, Col, Row } from "react-bootstrap";
import top_decoration from "../../images/top_decoration.png";
import MP from "../../images/Schmidt.jpg.png";
import Mari from "../../images/Mari.jpg.png";
import Maria from "../../images/Maria.jpg.png";
import Cata from "../../images/Catalin.jpg.png";
import Alexa from "../../images/Alexa.jpg.png";
import Andrei from "../../images/Andrei.jpg.png";
import Carmen from "../../images/Carmen.jpg.png";
import Anghel from "../../images/Anghel.jpg.png";
import Tarik from "../../images/Tarik.jpg.png";
import Luci from "../../images/Luci.jpg.png";
import Seba from "../../images/Seba.jpg.png";
import Ana from "../../images/Ana.jpg.png";
import Leo from "../../images/Leo.jpg.png";
import Piele from "../../images/Piele.jpg.png";
import "./Team.css";
import phone from "../../images/phone.png";
import mail from "../../images/mail.png";

interface TeamMemberProps {
  name: string;
  role: string;
  phoneNumber: string;
  linkedIn: string;
  photoMember: string;
}

const TeamMember: React.FC<TeamMemberProps> = ({ name, role, phoneNumber, linkedIn, photoMember}) => (
  <Container className="member">
    <img src={photoMember} alt="video" className="photo" />
    <div className="inline">
      <h6 className="white w700 nomargin">{name}</h6>
      <p className="text-about nomargin">{role}</p>

      <div className="m-0">
        <img src={phone} alt="phone" style={{ paddingRight: '1vh' }} />
        <p className="text text-about small inline">{phoneNumber}</p>
      </div>

      <div>
        <img src={mail} alt="mail" style={{ paddingRight: '1vh' }}/>
        <p className="text text-about small inline">{linkedIn}</p>
      </div>
    </div>
  </Container>
);

const Team = () => {
  const teamMembers = [
    {
      name: 'Schmidt Andrei-Cosmin',
      role: 'Manager de Proiect',
      phoneNumber: '+40 738 176 742',
      linkedIn: 'andrei219.as@gmail.com',
      photoMember: MP,
    },
    {
      name: 'Cotoi Marinela',
      role: 'Vice Responsabil',
      phoneNumber: '+40 767 102 397',
      linkedIn: 'marinela.cotoi@lsacbucuresti.ro',
      photoMember: Mari,
    },
    {
      name: 'Dinu Maria',
      role: 'Manager FR',
      phoneNumber: '+40 766 550 431',
      linkedIn: 'maria.dinu@lsacbucuresti.ro',
      photoMember: Maria,
    },
    {
      name: 'Giuglan Cătălin',
      role: 'Manager PR',
      phoneNumber: '+40 720 636 299',
      linkedIn: 'catalin.giuglan@lsacbucuresti.ro',
      photoMember: Cata,
    },
    {
      name: 'Bulgaru Alexandra',
      role: 'Manager IT',
      phoneNumber: '+40 756 025 021',
      linkedIn: 'ba.alexa.bulgaru@gmail.com',
      photoMember: Alexa,
    },
    {
      name: 'Iancu Andrei-Cătălin',
      role: 'Manager Design',
      phoneNumber: '+40 752 135 896',
      linkedIn: 'andrei.iancu1407@stud.acs.upb.ro',
      photoMember: Andrei,
    },
    {
      name: 'Murărescu Carmen-Maria',
      role: 'Manager HR',
      phoneNumber: '+40 744 353 046',
      linkedIn: 'carmenmurarescu17@gmail.com',
      photoMember: Carmen,
    },
    {
      name: 'Anghel Andrei-Stelian',
      role: 'Manager Game Master',
      phoneNumber: '+40 733 689 945',
      linkedIn: 'andreianghel2011@gmail.com',
      photoMember: Anghel,
    },
    {
      name: 'Omer Tarik',
      role: 'Manager Logistică',
      phoneNumber: '+40 740 689 270',
      linkedIn: 'tarikomer00@gmail.com',
      photoMember: Tarik,
    },
    {
      name: 'Popaz Lucian',
      role: 'Manager Multimedia',
      phoneNumber: '+40 771 337 489',
      linkedIn: 'lucian.popaz@gmail.com',
      photoMember: Luci,
    },
    {
      name: 'Severin Sebastian',
      role: 'Mentor',
      phoneNumber: '+40 773 744 237',
      linkedIn: 'sebastian27severin@gmail.com',
      photoMember: Seba,
    },
    {
      name: 'Almăjanu Ana Gabriela',
      role: 'Mentor',
      phoneNumber: '+40 725 565 656',
      linkedIn: 'ana.almajanu@gmail.com',
      photoMember: Ana,
    },
    {
      name: 'Mihai Andrei-Leonard',
      role: 'Mentor',
      phoneNumber: '+40 734 416 575',
      linkedIn: 'leonard.mihai@lsacbucuresti.ro',
      photoMember: Leo,
    },
    {
      name: 'Piele Mihai Teodor',
      role: 'Mentor',
      phoneNumber: '+40 720 773 602',
      linkedIn: 'pielemihai1709@gmail.com',
      photoMember: Piele,
    }
  ]

  return (
    <Container fluid className="pad" id="about">
      <img
        className="py-5 separator"
        src={top_decoration}
        alt={top_decoration}
      />
      <Row className="border-image">
        <Col
          className="team-col pe-md-5 mb-5"
          xl={8}
          lg={6}
          md={12}
          sm={12}
          xs={12}
        >
          <Container>
            <h1 className="white w700 p-2">The team </h1>
          </Container>
          {teamMembers.map((member, index) => (
            <TeamMember key={index} {...member} />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Team;
