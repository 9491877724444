import { MouseEventHandler } from 'react';
import Button from 'react-bootstrap/Button';
import './CustomButton.css';

type Props = {
    children?: React.ReactNode,
    clickFunc?: MouseEventHandler<HTMLButtonElement>,
    className?: string
};

function CustomButton({children, clickFunc, className}: Props) {
    return (
        <button onClick={clickFunc} className={'custom-button ' + className} type='submit'>
            {children}
        </button>
    );
}

export default CustomButton;
