import React from "react";
import Page from "./pages/Page/Page";
import Timer from "./pages/Timer/Timer";
import Profile from "./pages/Profile/Profile";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ForgotPassword/ResetPassword";
import Contact from "./pages/Contact/Contact";
import Sponsors from "./pages/Sponsors/Sponsors";
import About from "./pages/About/About";

import Search from "./pages/Search/Search";
import Rules from "./pages/Rules/Rules";
import PastEditions from "./pages/Past/PastEditions";
import Team from "./pages/Team/Team";
import Leaderboard from "./pages/Leaderboard/Leaderboard";
import Verify from "./pages/Verify/Verify"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          element={
            <Page>
              <Timer />
              <About />
              <PastEditions />
              <Sponsors />
              <Team />
              <Contact />
            </Page>
          }
        />
        <Route
          path="/profile"
          element={
            <Page>
              <Profile />
            </Page>
          }
        />
        <Route
          path="/login"
          element={
            <Page>
              <Login />
            </Page>
          }
        />
        <Route
          path="/register"
          element={
            <Page>
              <Register />
            </Page>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <Page>
              <ForgotPassword />
            </Page>
          }
        />
        <Route
          path="/reset-password/:resetToken"
          element={
            <Page>
              <ResetPassword />
            </Page>
          }
        />
        <Route
          path="/rules"
          element={
            <Page>
              <Rules />
            </Page>
          }
        />
        <Route
          path="/leaderboard"
          element={
            <Page>
              <Leaderboard />
            </Page>
          }
        />
        <Route
          path="/search"
          element={
            <Page>
              <Search />
            </Page>
          }
        />
        <Route
          path="/checkAccount/:token"
          element={
            <Page>
              <Verify />
            </Page>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
