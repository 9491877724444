import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CustomButton from '../../common/CustomButton';
import axios from 'axios';
import * as Yup from 'yup';
import './Login.css';

function Login() {
  const token = localStorage.getItem('token');
  const [loginError, setLoginError] = useState('');
  const nav = useNavigate();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            'Email is invalid',
          )
          .required('Email missing'),
        password: Yup.string()
          .min(6, 'Password is invalid')
          .required('Password missing'),
      }),
    [],
  );

  const handleSubmit = async (
    values: { email: string; password: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    try {
      const response = await loginUser(values);
      localStorage.setItem('token', response.token);
      setSubmitting(false);
      window.location.href = '/profile';
    } catch (error: any) {
      setLoginError(error.message);
      setSubmitting(false);
    }
  };

  const loginUser = async (values: { email: string; password: string }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/login`,
        values,
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  };

  return (
    <Container>
      <div className="align-items-center border border-margin border-danger px-md-5 py-2">
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) =>
            handleSubmit(values, { setSubmitting })
          }
        >
          {(formik) => (
            <div className="ps-3 pe-3 pb-2">
              <p className="text-center white large justify w700 p-3">Log In</p>
              <Form>
                <Row className="mb-4 justify-content-around">
                  <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                    <p className="ms-2 text white medium justify w700">Email</p>
                    <Field
                      className="autofill p-2 border-margin border-danger text white small transparent-background large-field"
                      type="email"
                      name="email"
                      placeholder="Email"
                    />
                    <ErrorMessage name="email">
                      {(msg) => (
                        <p className="ms-2 text red small justify">{msg}</p>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
                <Row className="mb-4 justify-content-around">
                  <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                    <p className="ms-2 text white medium justify w700">
                      Password
                    </p>
                    <Field
                      className="autofill p-2 border-margin border-danger text white small transparent-background large-field"
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                    <ErrorMessage name="password">
                      {(msg) => (
                        <p className="ms-2 text red small justify">{msg}</p>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
                {loginError && (
                  <p className="text red medium text-center pb-2">
                    {loginError}
                  </p>
                )}
                <CustomButton>
                  <p className="text white medium justified mx-5 p-1">Log In</p>
                </CustomButton>
              </Form>

              <div className="split-two pt-3">
                <p className="text white font-weight-normal small inline">
                  Don't have an account?{' '}
                </p>
                <a
                  href="/register"
                  className="text white small-medium w700 no-under"
                >
                  Register here
                </a>
              </div>
              <div>
                <a
                  href="/forgot-password"
                  className="text white small-medium inline w700 no-under"
                >
                  Forgot password
                </a>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </Container>
  );
}

export default Login;
