export const LeagueOfLegendsRules = () => (
  <div style={{ textAlign: 'justify'}}>
    {/* League of Legends rules go here */}
    {/* <p className="text white medium-large ms-5">League Heading: </p> */}
    {/* <p className="text white">Paragraph</p> */}
    <p className="text red medium-large mt-1"> Organizare: </p>
    <ul className="text white ms-3">
      <li>Competiția va avea loc <font color="red">sâmbătă 23 martie începând cu ora 11:00.</font></li>
      <li>Prezența în cadrul evenimentului se va face obligatoriu cu un <font color="red">act de identitate (de preferat cu buletinul) și carnetul/legitimația de student​.</font></li>
      <li>Liderul este reprezentantul echipei și purtătorul de cuvânt. Acesta anunță GameMasterii cu privire la orice problemă.</li>
      <li>Fiecare jucator trebuie să vină cu setup-ul lui (PC/laptop + monitor + periferice).</li>
      <li>Fiecare echipă va fi formată din <font color="red">5-6 jucători</font> (maxim o rezervă). Al 6-lea jucător nu poate fi rezervă în cadrul mai multor echipe.</li>
    </ul>
    <p className="text red medium-large mt-1"> Desfășurare: </p>
    <ul className="text white ms-3">
      <li>Conturile jucătorilor vor fi pe <font color="red">Europa North&East (EUNE)</font> și vor reprezenta conturile <font color="red">“main”</font> ale jucătorilor (contul activ curent sau contul cu cele mai multe ore jucate).</li>
      <li>Meciurile se vor desfășura în format <font color="red">“Custom Game”, “Tournament Draft Mode”,</font> folosind harta <font color="red">“Summoner’s Rift”.</font>​</li>
      <li>Jocurile se vor desfășura pe platforma <font color="red">Faceit</font>, prin intermediul funcționalității de <font color="red">turnee.</font></li>
      <li>Orice <font color="red">pauză</font> solicitată trebuie însoțită de <font color="red">argumente solide</font> și va necesita <font color="red">acordul</font> unui GameMaster; în caz de pauză, înainte de a reîncepe meciul este obligatoriu ca echipa adversă să confirme încetarea pauzei.</li>
      <li>Fiecare echipă are dreptul la o <font color="red">pauză</font> de maxim 10 minute <font color="red">între meciuri.</font></li>
      <li>În cazul în care o echipă se simte nedreptățită, liderul poate cere <font color="red">asistență</font> din partea GameMasterilor, care vor analiza situația și vor propune soluții. Orice cerere trebuie să fie sprijinită de argumente și informații valide, dar și adusă la cunoștință <font color="red">cât mai repede.</font></li>
    </ul>
    <p className="text red medium-large mt-1"> Format: </p>
    <ul className="text white ms-3">
      <li><font color="red">Formatul</font> competiției va fi de <font color="red">4 grupe de câte 4 echipe,</font> calificându-se apoi 8 echipe într-un <font color="red">bracket piramidal.</font> Echipele care se califică vor fi <font color="red">primele 2</font> din fiecare grupă.</li>
      <li>Departajarea în cadrul grupei se va face după <font color="red">least time to win</font> în caz de egalitate​.</li>
      <li>Finalele se vor desfășura în format <font color="red">Best of Three.</font></li>
    </ul>
    <p className="text red medium-large mt-1"> Sancțiuni: </p>
    <ul className="text white ms-3">
      <li>În cazul în care un membru al unei echipe <font color="red">refuză</font> să respecte regulile și instrucțiunile organizatorilor sau nu are un comportament <font color="red">fair-play</font>, organizatorii îl pot avertiza, iar în funcție de gravitatea situației, întreaga echipă poate fi <font color="red">descalificată.</font> În această categorie intră:</li>
      <ul style={{ listStyleType: 'square'}}>
        <li>Refuzul jucătorilor de a rămâne conectați pe voice channel pe toată durata desfășurării meciului în care sunt implicați - <font color="red">doar la etapa preliminară.</font></li>
        <li><font color="red">Neprezentarea la check-in</font> în intervalul alocat sau <font color="red">refuzarea</font> prezentării documentelor menționate.</li>
        <li>Dacă un jucător nu a folosit <font color="red">main account-ul,</font> vor exista sancționări pentru echipă, cu posibilitatea <font color="red">descalificării</font> acesteia, în funcție de gravitatea situației.</li>
      </ul>
      <li>În cazul eventualelor <font color="red">probleme tehnice nedatorate organizatorilor,</font> se acordă o perioadă de <font color="red">15 minute</font> pentru rezolvarea acestora. Dacă problema nu este rezolvată, echipa adversă are dreptul de a decide dacă dorește sau nu să prelungească perioada de întrerupere tehnică. În cazul unor defecțiuni multiple, echipa cu <font color="red">mai mulți jucători eligibili</font> are dreptul de decizie.</li>
    </ul>
    <p className="text red medium-large mt-1"> Atenție! </p>
    <ul className="text white ms-3">
      <li>Un jucător implicat în alte competiții individuale va avea obligația de a renunța la acestea dacă vor contraveni programului de desfășurare al acestei competiții.</li>
      <li>Un jucător va fi așteptat pentru începerea unui meci până la <font color="red">maxim 10 minute.</font> După expirarea acestui timp, dacă jucătorul nu s-a întors, meciul va fi câștigat la masa verde de către echipa adversă.</li>
      <li>În cazul în care apare o situație neprevăzută în regulament, echipa de organizare își  rezervă dreptul de a rezolva situația pe moment.</li>
      <li>Completări adiționale la acest link: <a href="https://docs.google.com/document/d/1Vib9SQ_lVZAzc8iA6HiMMNYqDAOb1YyJ/edit?usp=drive_link&ouid=112881897506255238496&rtpof=true&sd=true"><font color="red">Ghid Faceit.</font></a></li>
      <li>În cazul sabotării intenționate a unor participanți (oprire alimentare, oprire acces la internet) echipa respectivă poate fi sancționată și eliminată din competiție.</li>
    </ul>
  </div>
);
  

export const CSGORUles = () => (
  <div style={{ textAlign: 'justify'}}>
    {/* CSGO rules go here */}
    {/* <p className="text white medium-large ms-5">CSGO Heading: </p>
    <p className="text white">Paragraph</p> */}
    <p className="text red medium-large mt-1"> Organizare: </p>
    <ul className="text white ms-3">
      <li>Competiția va avea loc <font color="red">duminică 24 martie începând cu ora 11:00.</font></li>
      <li>Prezența în cadrul evenimentului se va face obligatoriu cu un <font color="red">​act de identitate</font> ​(de preferat cu buletinul) și <font color="red">​carnetul/legitimația de student​.</font></li>
      <li>Fiecare jucător trebuie să vină cu setup-ul lui (PC + monitor sau laptop + periferice).</li>
      <li>Jocurile vor fi jucate pe <font color="red">Faceit</font>, în cadrul unor hub-uri pe care participanții vor trebui să intre înainte de începerea evenimentului.</li>
      <li>Fiecare participant trebuie să instaleze <font color="red">Faceit Anti-Cheat ÎNAINTE</font> de ziua evenimentului.</li>
      <li>Jucătorii se vor conecta la server folosind comanda: <font color="red">connect “adresa-ip”.</font></li>
      <li>Fiecare echipă va fi formată din <font color="red">5-6 jucători</font> (maxim o rezervă). Al 6-lea jucător nu poate fi rezervă în cadrul mai multor echipe.</li>
    </ul>
    <p className="text red medium-large mt-1"> Desfășurare: </p>
    <ul className="text white ms-3">
      <li>Se vor juca hărțile: <font color="red">Mirage, Vertigo, Inferno, Overpass, Ancient, Nuke, Anubis.</font></li>
      <li>Căpitanii vor <font color="red">bana</font> pe rând câte o hartă (din cele 7 hărți, fiecare lider va bana 3 hărți, astfel va rămâne harta pe care se va juca). Echipa care banează prima hartă va fi aleasă <font color="red">aleatoriu.</font> După intrarea jucătorilor pe server se va juca o rundă <font color="red">“knife-only”,</font> iar echipa câștigătoare va alege <font color="red">side-ul</font> pe care să înceapă (CT sau T).</li>
      <li>După fiecare rundă de câte 3 meciuri se va face o pauză de 10 minute.</li>
      <li>Dacă un meci din cele 3 va ieși egal, se va juca <font color="red">overtime</font> de ”Best of 6 rounds” (3 runde pe partea de CT, respectiv 3 runde pe partea de T cu 10000 cash de fiecare parte).</li>
      <li>Dacă va apărea vreo problema tehnică, se va folosi o comandă pentru a pune pauză meciului.</li>
      <li>Modul de desfășurare al competiției este reprezentat de <font color="red">grupe</font> urmat de un <font color="red">bracket piramidal.</font> Doar în <font color="red">finale</font> meciurile vor fi <font color="red">Best of Three.</font></li>
      <li>Pentru faza de grupe, două echipe care termină grupele cu același scor (ex: 2-1, 2-1), vor fi <font color="red">departajate</font> în funcție de <font color="red">deficitul de runde</font> (număr runde câștigate minus număr runde pierdute).</li>
    </ul>
    <p className="text red medium-large mt-1"> Setări de joc: </p>
    <ul className="text white ms-3">
      <li>Jucătorii NU au voie să modifice următoarele setări:</li>
      <ul style={{ listStyleType: 'square'}}>
        <li>cl_bobcycle 0.98</li>
        <li>weapon_recoil_model 0</li>
        <li>mat_hdr_level 0/1/2</li>
        <li>rate 786432</li>
      </ul>
      <li>Jucătorii au voie să modifice următoarele setări:</li>
      <ul style={{ listStyleType: 'square'}}>
        <li>net_graph 0</li>
        <li>cl_showpos 1</li>
        <li>cl_showfps 1</li>
        <li>cl_showdemooverlay 1/-1</li>
      </ul>
      <li>Setările server-ului vor fi următoarele:</li>
      <ul style={{ listStyleType: 'square'}}>
        <li>RUNDA KNIFE ONLY:</li>
          <ul style={{ listStyleType: 'circle'}}>
            <li>mp_startmoney 0</li>
            <li>mp_buytime 0</li>
            <li>mp_maxrounds 1</li>
            <li>sv_pausable 1</li>
          </ul>
        <li>RUNDĂ NORMALĂ:</li>
        <ul style={{ listStyleType: 'circle'}}>
          <li>sv_pausable 1</li>
          <li>mp_c4timer 40</li>
        </ul>
        <li>RUNDĂ OVERTIME:</li>
        <ul style={{ listStyleType: 'circle'}}>
            <li>sv_pausable 1</li>
            <li>mp_maxrounds 6</li>
            <li>mp_startmoney 10000</li>
            <li>Mp_c4timer 40</li>
          </ul>
      </ul>
    </ul>
    <p className="text red medium-large mt-1"> Sancțiuni: </p>
    <ul className="text white ms-3">
      <li>Este <font color="red">interzisă</font> folosirea oricăror cheat-uri (script-uri, hack-uri, wall, aim, radar hack, etc.).</li>
      <li>GameMasterii își pot rezerva oricând dreptul de a verifica consola, ecranul sau orice alt mijloc care poate răspunde unei suspiciuni, oricărui jucător, în cazul în care este semnalată o tentativă de trișare.</li>
      <li>În cazul în care un membru al unei echipe <font color="red">refuză</font> să respecte regulile și instrucțiunile organizatorilor sau nu are un comportament <font color="red">fair-play,</font> organizatorii îl pot <font color="red">avertiza,</font> iar în funcție de gravitatea situației, întreaga echipă poate fi <font color="red">descalificată.</font> În această categorie intră:</li>
      <ul style={{ listStyleType: 'square'}}>
        <li><font color="red">Neprezentarea la check-in</font> în intervalul alocat sau <font color="red">refuzarea</font> prezentării documentelor menționate.</li>
      </ul>
      <li>În cazul eventualelor <font color="red">probleme tehnice nedatorate organizatorilor,</font> se acordă o perioadă de <font color="red">15 minute</font> pentru rezolvarea acestora. Dacă problema nu este rezolvată, echipa adversă are dreptul de a decide dacă dorește sau nu să prelungească perioada de întrerupere tehnică. În cazul unor defecțiuni multiple, echipa cu mai mulți jucători eligibili are dreptul de decizie.</li>
      <li>Un jucător va fi așteptat pentru începerea unui meci până la <font color="red">maxim 10 minute.</font></li>
    </ul>
    <p className="text red medium-large mt-1"> Atenție! </p>
    <ul className="text white ms-3">
      <li>Opțiunea <font color="red">Secure Boot</font> trebuie activată din <font color="red">BIOS</font> pentru a putea rula <font color="red">Faceit Anti-Cheat.</font></li>
      <li>Un jucător implicat în alte competiții individuale va avea obligația de a renunța la acestea dacă vor contraveni programului de desfășurare al acestei competiții.</li>
      <li>În cazul în care un participant se simte nedreptățit, acesta poate cere <font color="red">asistență</font> din partea GameMasterilor, care vor analiza situația și vor propune soluții. Orice cerere trebuie să fie sprijinită de argumente și informații valide, dar și adusă la cunoștință cât mai repede. </li>
      <li>În cazul în care apare o situație neprevăzută în regulament, echipa de organizare își  rezervă dreptul de a rezolva situația pe moment.</li>
      <li>Completări adiționale la acest link: <a href="https://docs.google.com/document/d/1Vib9SQ_lVZAzc8iA6HiMMNYqDAOb1YyJ/edit?usp=drive_link&ouid=112881897506255238496&rtpof=true&sd=true"><font color="red">Ghid Faceit.</font></a></li>
      <li>În cazul sabotării intenționate a unor participanți (oprire alimentare, oprire acces la internet) echipa respectivă poate fi sancționată și eliminată din competiție.</li>
    </ul>
  </div>
);

export const RocketLeagueRules = () => (
  <div style={{ textAlign: 'justify'}}>
    {/* Rocket League rules go here */}
    {/* <p className="text white medium-large ms-5">RocketLeague Heading: </p>
    <p className="text white">Paragraph</p> */}
    <p className="text red medium-large mt-1"> Organizare: </p>
    <ul className="text white ms-3">
      <li>Competiția va avea loc <font color="red">sâmbătă 23 martie începând cu ora 15:00.</font></li>
      <li>Prezența în cadrul evenimentului se va face obligatoriu cu un <font color="red">act de identitate (de preferat cu buletinul) și carnetul/legitimația de student.</font>​</li>
      <li><font color="red">Liderul</font> este reprezentantul echipei și purtătorul de cuvânt. Acesta anunță GameMasterii cu privire la orice problemă.</li>
      <li>Fiecare jucător trebuie să vină cu setup-ul lui (PC/laptop + monitor + periferice).</li>
      <li>Fiecare echipă va fi formată din <font color="red">2 jucători</font> plus eventual o rezervă (maxim una). În cazul în care unul dintre coechipieri nu poate participa, acesta poate fi înlocuit de rezervă. Nu sunt permise schimbări după <font color="red">începerea turneului.</font> De asemenea, al 3-lea jucător nu poate fi rezervă în cadrul <font color="red">mai multor echipe.</font></li>
    </ul>
    <p className="text red medium-large mt-1"> Desfășurare: </p>
    <ul className="text white ms-3">
      <li>Formatul turneului va fi unul <font color="red">piramidal:</font> meciurile sunt <font color="red">Best of One,</font> iar finalele <font color="red">Best of Three.</font></li>
      <li>Participanții care nu sunt conectați la momentul începerii vor fi <font color="red">descalificați.</font></li>
      <li>Bracket-ul va fi <font color="red">randomizat</font> în ziua evenimentului înainte de start.</li>
    </ul>
    <p className="text red medium-large mt-1"> Setări ale turneului: </p>
    <ul className="text white ms-3">
      <li><font color="red">Game mode:</font> Soccar;</li>
      <li><font color="red">Team size:</font> 2v2;</li>
      <li><font color="red">Bracket size:</font> 16;</li>
      <li><font color="red">Tree matches:</font> Best of 1;</li>
      <li><font color="red">Finals Match:</font> Best of 3;</li>
      <li><font color="red">Tiebreaker:</font> First to score;</li>
      <li><font color="red">Joinable by:</font> Name/Password;</li>
      <li><font color="red">Region:</font> Europe;</li>
      <li><font color="red">Max time limit:</font> 11 minute;</li>
      <li><font color="red">Mutators:</font></li>
      <ul style={{ listStyleType: 'square'}}>
        <li>Match length: 5 minute;</li>
        <li>Max score: default;</li>
        <li>Max time limit: 11 minute;</li>
        <li>Game speed: default;</li>
        <li>Ball Max Speed: default;</li>
        <li>Ball type: default;</li>
        <li>Ball physics: default;</li>
        <li>Ball size: default;</li>
        <li>Ball bounciness: default;</li>
        <li>Boost amount: default;</li>
        <li>Rumble: none;</li>
        <li>Boost Strength: 1x;</li>
        <li>Gravity: default;</li>
        <li>Demolish: default;</li>
        <li>Respawn time: 3 secunde.</li>
      </ul>
      <li><font color="red">Hărțile adăugate:</font></li>
      <ul style={{ listStyleType: 'square'}}>
        <li>DFH Stadium</li>
        <li>Urban Central</li>
        <li>Beckwith Park</li>
        <li>Utopia Coliseum</li>
        <li>Mannfield</li>
        <li>Wasteland</li>
        <li>AquaDome</li>
        <li>Neo Tokyo</li>
        <li>Champions Field</li>
        <li>Farmstead</li>
        <li>Salty Shores</li>
        <li>Forbidden Temple</li>
        <li>Neon Fields</li>
        <li>Deadeye Canyon</li>
        <li>Sovereign Heights</li>
        <li>Estadio Vida</li>
      </ul>
    </ul>
    <p className="text red medium-large mt-1"> Sancțiuni: </p>
    <ul className="text white ms-3">
      <li>În cazul în care un membru al unei echipe <font color="red">refuză</font> să respecte regulile și instrucțiunile organizatorilor sau nu are un comportament <font color="red">fair-play,</font> organizatorii îl pot <font color="red">avertiza,</font> iar în funcție de gravitatea situației, întreaga echipă poate fi <font color="red">descalificată.</font> În această categorie intră:</li>
      <ul style={{ listStyleType: 'square'}}>
        <li>Refuzul jucătorilor de a rămâne conectați pe voice channel pe toată durata desfășurării meciului în care sunt implicați - <font color="red">doar la etapa preliminară.</font></li>
        <li><font color="red">Neprezentarea la check-in</font> în intervalul alocat sau <font color="red">refuzarea</font> prezentării documentelor menționate.</li>
      </ul>
      <li>Un jucător va fi așteptat pentru începerea unui meci până la <font color="red">maxim 10 minute.</font> După expirarea acestui timp, dacă jucătorul nu s-a întors, meciul va fi câștigat la masa verde de către echipa adversă.</li>
    </ul>
    <p className="text red medium-large mt-1"> Atenție! </p>
    <ul className="text white ms-3">
      <li>Un jucător implicat în alte competiții individuale va avea obligația de a renunța la acestea dacă vor contraveni programului de desfășurare al acestei competiții.</li>
      <li>Între meciuri nu vor exista pauze, decât cele garantate de sistemul de turneu implementat în joc (2-3 minute, până când toți jucătorii confirmă că sunt gata).</li>
      <li>În cazul în care o echipă se simte nedreptățită, liderul poate cere <font color="red">asistență</font> din partea GameMasterilor, care vor analiza situația și vor propune soluții. Orice cerere trebuie să fie sprijinită de argumente și informații valide, dar și adusă la cunoștință cât mai repede. </li>
      <li>În cazul în care apare o situație neprevăzută în regulament, echipa de organizare își  rezervă dreptul de a rezolva situația pe moment.</li>
      <li>În cazul sabotării intenționate a unor participanți (oprire alimentare, oprire acces la internet) echipa respectivă poate fi sancționată și eliminată din competiție.</li>
    </ul>
  </div>
);

export const FIFARules = () => (
  <div style={{ textAlign: 'justify'}}>
    {/* FIFA rules go here */}
    {/* <p className="text white medium-large ms-5"> FIFA Heading: </p>
    <p className="text white">Paragraph</p> */}
    <p className="text red medium-large mt-1"> Organizare: </p>
    <ul className="text white ms-3">
      <li>Competiția va avea loc pe <font color="red">24 martie,</font> înscrierile se fac fizic la eveniment de la ora 12, competiția începând cu ora <font color="red">15:30.</font></li>
      <li>Prezența în cadrul evenimentului se va face obligatoriu cu un <font color="red">​act de identitate</font> ​(de preferat cu buletinul) și <font color="red">​carnetul/legitimația de student​.</font></li>
      <li>Meciurile se vor juca pe consolele puse la dispoziție de către organizare.</li>
    </ul>
    <p className="text red medium-large mt-1"> Desfășurare: </p>
    <ul className="text white ms-3">
      <li>Modul de desfășurare al competiției este în <font color="red">sistem piramidal</font> cu maxim 64 de participanți, iar meciurile vor fi în format <font color="red">Best of One.</font> Semifinalele și finala vor fi în format <font color="red">Best of Three.</font></li>
      <li>Pentru a stabili persoana care își alege prima echipa, unul dintre GameMasteri va selecta unul dintre jucători printr-o metodă <font color="red">aleatorie - coinflip.</font> Jucătorul ce își va alege ultimul echipa va avea opțiunea de a <font color="red">bana</font> primul o echipă. Astfel, oponentul nu va putea alege respectiva echipă.</li>
      <li>În cazul în care apare o situație neprevăzută în regulament echipa de organizare își  rezervă dreptul de a rezolva situația pe moment.</li>
      <li>Participanții nu au voie să joace cu aceeași echipă.</li>
      <li>Participanții pot alege orice echipă, mai puțin echipele din categoria: <font color="red">Rest of the World.</font></li>
      <li>Dacă <font color="red">ambii</font> jucători doresc să joace cu echipe feminine, acest lucru este permis.</li>
      <li>La o diferență de 5 goluri, echipa care conduce este considerată câstigatoare și meciul se oprește.</li>
      <li>Echipele au ultimele transferuri, dar <font color="red">nu</font> se joacă cu Live Form.</li>
    </ul>
    <p className="text red medium-large mt-1"> Setări de joc: </p>
    <ul className="text white ms-3">
      <li><font color="red">Difficulty Level:</font> Legendary</li>
      <li><font color="red">Injuries:</font> On</li>
      <li><font color="red">Offside:</font> On</li>
      <li><font color="red">Time/Score Display:</font> On</li>
      <li><font color="red">Camera:</font> Tele Broadcast</li>
      <li><font color="red">Camera Zoom:</font> 0</li>
      <li><font color="red">Camera Height:</font> 17</li>
      <li><font color="red">Hands:</font> Off</li>
      <li><font color="red">Radar:</font> 2D or 3D</li>
      <li><font color="red">Overtime:</font> Prelungiri simple (încă 2 reprize), iar în caz de egalitate, penalti</li>
    </ul>
    <p className="text red medium-large mt-1"> Sancțiuni: </p>
    <ul className="text white ms-3">
      <li>În cazul în care un membru <font color="red">refuză</font> să respecte regulile și instrucțiunile organizatorilor sau nu are un comportament <font color="red">fair-play,</font> organizatorii îl pot <font color="red">avertiza,</font> iar în funcție de gravitatea situației, întreaga echipă poate fi <font color="red">descalificată.</font> În această categorie intră:</li>
      <ul style={{ listStyleType: 'square'}}>
        <li><font color="red">Neprezentarea la check-in</font> în intervalul alocat sau <font color="red">refuzarea</font> prezentării documentelor menționate.</li>
      </ul>
      <li>Un jucător va fi așteptat pentru începerea unui meci până la <font color="red">maxim 10 minute.</font> După expirarea acestui timp, dacă jucătorul nu s-a întors, meciul va fi câștigat la masa verde de către oponentul său.</li>
    </ul>
    <p className="text red medium-large mt-1"> Atenție! </p>
    <ul className="text white ms-3">
      <li>Un jucător implicat în alte competiții individuale va avea obligația de a renunța la acestea dacă vor contraveni programului de desfășurare al acestei competiții.</li>
      <li>Setările de joc se pot schimba în funcție de preferințele jucătorilor, <font color="red">doar cu înștiințarea</font> înainte a GameMasterilor.</li>
      <li><font color="red">Acordul pentru începerea meciului</font> îl va da unul dintre GameMasteri. Meciurile începute <font color="red">fără înștiințarea</font> GameMasterilor sunt considerate <font color="red">neoficiale.</font></li>
      <li>În cazul în care un participant se simte nedreptățit, acesta poate cere <font color="red">asistență</font> din partea GameMasterilor, care vor analiza situația și vor propune soluții. Orice cerere trebuie să fie sprijinită de argumente și informații valide, dar și adusă la cunoștință cât mai repede.</li>
      <li>În cazul în care apare o situație neprevăzută în regulament, echipa de organizare își  rezervă dreptul de a rezolva situația pe moment.</li>
      <li>În cazul sabotării intenționate a unor participanți (oprire alimentare, oprire acces la internet) echipa respectivă poate fi sancționată și eliminată din competiție.</li>
    </ul>
  </div>
);

