import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import CustomButton from "../../common/CustomButton";
import "./Contact.css";

import * as Yup from "yup";

import top_decoration from "../../images/top_decoration.png";
import phone from "../../images/phone.png";
import mail from "../../images/mail.png";
import location from "../../images/location.png";
import facebook from "../../images/facebook.png";
import instagram from "../../images/instagram.png";
import linkedin from "../../images/linkedin.png";
import youtube from "../../images/youtube.png";
import tiktok from "../../images/tiktok.png";
import lsac from "../../images/lsac.png";
import axios from "axios";

function Contact() {
  const [contactError, setContactError] = useState('');

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required("First name missing"),
        lastName: Yup.string().required("Last name missing"),
        teamName: Yup.string().required("Team name missing"),
        email: Yup.string()
          .email('Invalid email')
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            'Email is invalid',
          )
          .required('Email missing'),
        message: Yup.string().required("Message missing"),
      }),
    [],
  );

  const handleContact = async (
    values: {
      firstName: string;
      lastName: string;
      teamName: string;
      email: string;
      message: string;
    },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void}
  ) => {
    try {
      console.log("ana")
      const contactResponse = await contactUser(values);
      setContactError("Message sent!");
      setSubmitting(false);
    } catch (error: any) {
      setContactError(error.response.data.message || "Message could not be sent!");
      setSubmitting(false);
    }
  }

  const contactUser = async (values: {
    firstName: string;
    lastName: string;
    teamName: string;
    email: string;
    message: string;
  }) => {
    try {
      console.log("ana")
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/contact`, values,
      );
      return response.data;
    } catch (error: any) {
      throw new error.response.data;
    }
  }

  return (
    <Container id="contact" fluid className="pad mt-5">
      <img
        className="py-5 separator"
        src={top_decoration}
        alt={top_decoration}
      />

      <p className="text white large justify">Contact</p>

      <Row>
        <div className="align-items-center blur-bg border-margin border border-danger p-5">
          <div className="pb-5">
            <p className="text red medium-large inline">Send us </p>
            <p className="text white medium-large inline">a message</p>
          </div>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              teamName: "",
              email: "",
              message: "",
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values, { setSubmitting, resetForm }) => {
              console.log("ana")
              handleContact(values, { setSubmitting });
              resetForm();
            }}
          >
            {({ errors }) => (
              <Form>
                <Row className="row-centered">
                  <Row className="pb-3">
                    <Col className="py-2" xl={5} lg={5} md={12} sm={12} xs={12}>
                      <Field
                        className="autofill p-1 w-100 blur-bg border-2 border-danger text white small transparent-background"
                        type="text"
                        name="firstName"
                        placeholder="First name"
                      />
                    </Col>
                    <Col
                      className="py-2"
                      xl={2}
                      lg={2}
                      md={12}
                      sm={12}
                      xs={12}
                    />
                    <Col className="py-2" xl={5} lg={5} md={12} sm={12} xs={12}>
                      <Field
                        className="autofill p-1 w-100 blur-bg border-2 border-danger text white small transparent-background"
                        type="text"
                        name="lastName"
                        placeholder="Last name"
                      />
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col className="py-2" xl={5} lg={5} md={12} sm={12} xs={12}>
                      <Field
                        className="autofill p-1 w-100 blur-bg border-2 border-danger text white small transparent-background"
                        type="text"
                        name="teamName"
                        placeholder="Team Name"
                      />
                    </Col>
                    <Col
                      className="py-2"
                      xl={2}
                      lg={2}
                      md={12}
                      sm={12}
                      xs={12}
                    />
                    <Col className="py-2" xl={5} lg={5} md={12} sm={12} xs={12}>
                      <Field
                        className="autofill p-1 w-100 blur-bg border-2 border-danger text white small transparent-background"
                        type="text"
                        name="email"
                        placeholder="Email"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className="py-2"
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Field
                        className="autofill p-1 w-100 mb-3 blur-bg border-2 border-danger text white small transparent-background message-box"
                        type="text"
                        name="message"
                        placeholder="Message"
                        as="textarea"
                        rows="6"
                      />
                    </Col>
                  </Row>
                  {contactError && (
                    <p className="text red medium text-center">
                      {contactError}
                    </p>
                  )}

                  <button type="submit" className="submit-button" style={{ width: "200px"}}>
                    <p className="text white medium justified">Send</p>
                  </button>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </Row>
      <Row>
        <Container className="contact-box blur-bg border border-margin border-danger">
          <div className="contact-info">
            <div className="contact-components">
              <p className="text large justify">Find us at:</p>
            </div>

            <div className="contact-components">
              <img src={phone} alt={phone} style={{marginRight: "8px"}} />
              <p className="text small-medium inline"> +40 738 176 742</p>
            </div>

            <div className="contact-components">
              <img src={mail} alt={mail} style={{marginRight: "14px"}} />
              <p className="text small-medium inline">
                lanparty@lsacbucuresti.ro
              </p>
            </div>

            <div className="contact-components">
              <img src={location} alt={location} style={{marginRight: "20px"}} />
              <p className="text small-medium inline">
                Bulevardul Iuliu Maniu 6d, București 061344
              </p>
            </div>
          </div>

          <div className="social-links">
            <div className="social-components">
              <a href="https://www.facebook.com/LsacBucuresti/">
                <img src={facebook} alt={facebook} className="social-logo" />
                <p className="text small-medium inline social-text"> Facebook</p>
              </a>
            </div>
            
            <div className="social-components">
              <a href="https://www.instagram.com/lsacbucuresti/">
                <img src={instagram} alt={instagram} className="social-logo" />
                <p className="text small-medium inline social-text"> Instagram</p>
              </a>
            </div>
            
            <div className="social-components">
              <a href="https://www.linkedin.com/company/lsac-bucuresti/">
                <img src={linkedin} alt={linkedin} className="social-logo" />
                <p className="text small-medium inline social-text"> LinkedIn</p>
              </a>
              
            </div>
            
            <div className="social-components">
              <a href="https://www.youtube.com/user/LSACBucuresti">
                <img src={youtube} alt={youtube} className="social-logo" />
                <p className="text small-medium inline social-text"> Youtube</p>
              </a>
            </div>
            
            <div className="social-components">
              <a href="https://www.tiktok.com/@lsacbucuresti">
                <img src={tiktok} alt={tiktok} className="social-logo" />
                <p className="text small-medium inline social-text"> TikTok</p>
              </a>
            </div>
            
            <div className="social-components">
              <a href="https://lsacbucuresti.ro/">
                <img src={lsac} alt={lsac} className="social-logo" />
                <p className="text small-medium inline social-text"> lsacbucuresti.ro</p>
              </a>
            </div>
          </div>
        </Container>
      </Row>
    </Container>
  );
}

export default Contact;
