import { RankInterface } from "../../Interfaces";
import { Row, Col } from 'react-bootstrap';
import './LinkCard.css'
import copyLinkIcon from '../../../../images/CopyLinkIcon.png';

interface Props {
    rank: RankInterface
}

function LinkCard({ rank }: Props) {
    return (
        <div className="container p-0 m-0">
            <Row className="p-0 m-0 justify-content-between cols-2">
                <Col xs={2} className="m-0 p-0">
                    <p className='text white medium justify'>Profile link</p>
                </Col>
                <Col xs={3} className="m-0 p-0">
                    <div className="text-end">
                        <span className='text white medium justify'>Game</span>
                        <span className='text red justify leftSpace'>{rank.game}</span>
                    </div>
                </Col>
            </Row>

            <div className="right-side border border-danger blur-bg linkBox" style={{ marginBottom: '5vh'}}>
                <a href={rank.link} className="text-muted medium justify">
                    {rank.link}
                </a>
                <img src={copyLinkIcon} alt="" className='copyLinkImg' onClick={() => {navigator.clipboard.writeText(rank.link)}}/>
            </div>
        </div>
    )
}

export default LinkCard;