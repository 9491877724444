import { Col, Row, Image } from "react-bootstrap";
import { JoinRequestInterface } from "../../Interfaces";
import { useEffect, useState } from "react";
import axios from "axios";
import './JoinRequestCard.css';
import CustomButton from "../../../../common/CustomButton";

interface Props {
    request: JoinRequestInterface,
    onClickAccept: (requestId: string) => void
    onClickDecline: (requestId: string) => void
}

function JoinRequestCard({ request, onClickAccept, onClickDecline }: Props) {
    const token = localStorage.getItem('token');
    const [img, setImg] = useState('');

    useEffect(() => {
        axios({
            method: "get",
            url: process.env.REACT_APP_URL + "/join-request/image/" + request.id,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            return setImg(res.data.imageUrl);

        }).catch(e => {
            console.log(e);
        })
    }, [])

    return (
        <>
            {/* <Row>
                <Col xl={2} lg={3} md={2} sm={3} xs={4} className='picture-request'>
                    <Image src={img} alt={img} className='profile-img-border' style={{height: '13vh', width: '13vh'}} />
                </Col>
                <Col xl={10} lg={9} md={10} sm={9} xs={8} className='pt-2 pb-2 border border-danger text-start'>
                    <Row>
                        <Col xl={4} lg={12} md={3} sm={12} xs={12}>
                            <p className='text white small'>Profile name</p>
                        </Col>
                        <Col xl={8} lg={12} md={9} sm={12} xs={12}>
                            <p className='text red small text-truncate'>{request.gameUsername}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4} lg={12} md={3} sm={12} xs={12}>
                            <p className='text white small'>Rank</p>
                        </Col>
                        <Col xl={8} lg={12} md={9} sm={12} xs={9}>
                            <p className='text red small'>{request.playerRank}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4} lg={12} md={3} sm={12} xs={12}>
                            <p className='text white small'>Phone number</p>
                        </Col>
                        <Col xl={8} lg={12} md={9} sm={12} xs={12}>
                            <p className='text red small text-truncate'>{request.playerPhone}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4} lg={12} md={3} sm={12} xs={12}>
                            <p className='text white small'>Status</p>
                        </Col>
                        <Col xl={8} lg={12} md={9} sm={12} xs={12}>
                            <p className='text red small text-truncate'>{request.status}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {   request.status === "PENDING" ? 
                <Row>
                    <Col xl={2} lg={3} md={2} sm={3} xs={4} className='my-auto'>
                    </Col>
                    <Col xl={5} lg={4} md={5} sm={5} xs={4} className='pt-2 pb-2'>
                        <div onClick={() => onClickAccept(request.id)}>
                            <CustomButton className="acceptBtn"><p className='text white medium p-1'>Accept in team</p></CustomButton>
                        </div>
                    </Col>
                    <Col xl={5} lg={4} md={5} sm={5} xs={4} className='pt-2 pb-2'>
                        <div onClick={() => onClickDecline(request.id)}>
                            <CustomButton className="acceptBtn"><p className='text white medium p-1'>Decline request</p></CustomButton>
                        </div>
                    </Col>
                </Row> : <div></div>
            }

            <div className="pb-3" /> */}
        </>
    );
}

export default JoinRequestCard;