import { Image, Col, Row, Tab, Nav, Container } from "react-bootstrap";
import './Profile.css';
import { TeamInterface, UserInterface } from "./Interfaces";

import moon from "../../images/moon.svg";
import saturn from "../../images/saturn.svg";
import CustomButton from "../../common/CustomButton";

import ProfilePrivateData from "./Data/ProfilePrivateData";
import ProfileData from "./Data/ProfileData";
import ProfileEdit from "./Edit/ProfileEdit";
import ProfileJoinTeam from "./JoinTeam/ProfileJoinTeam";
import ProfileEditTeam from "./EditTeam/EditTeam";
import CreateTeam from "./CreateTeam/CreateTeam";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Profile() {
    const token = localStorage.getItem("token");
    const [user, setUser] = useState({
        lastName: "", firstName: "", description: "", email: "", id: "", image: "", profileName: "", phone: "", teams: {}, ranks: [{}]
    } as UserInterface);
    const [teams, setTeams] = useState(Array<TeamInterface>);
    const [hasTeams, setHasTeams] = useState(false);
    const nav = useNavigate();

    useEffect(() => {
        axios({
            method: "get",
            url: process.env.REACT_APP_URL + "/profile/myProfile",
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            setUser({
                lastName: res.data.lastName,
                firstName: res.data.firstName,
                description: res.data.description,
                email: res.data.email,
                id: res.data.id,
                image: res.data.imageUrl,
                profileName: res.data.profileName,
                phone: res.data.phone,
                teams: res.data.teams,
                ranks: res.data.rank.map((rank: any) => {
                    return {
                        game: rank.game,
                        rank: rank.rank,
                        gameUsername: rank.gameUsername,
                        link: rank.link
                    }
                })
            })

            axios({
                method: "get",
                url: process.env.REACT_APP_URL + "/profile/myProfile/teams",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(res => {
                setHasTeams(res.data.length != 0);
                setTeams(res.data.map((team: any) => {
                    let captainId = team.captainId;

                    return {
                        id: team.id,
                        name: team.name,
                        captainId: captainId,
                        description: team.description,
                        competition: team.games,
                        token: team.token,
                        image: team.imageUrl ? team.imageUrl : "",
                        teammates: [{}]
                    }
                }))
            }).catch(e => {
                console.log(e);
            })
        }).catch(e => {
            console.log(e);
            nav("/login");
        })
    }, [])

    return (
        <Tab.Container defaultActiveKey="profile-data">
            <Row className="mx-lg-5 overflow-hidden">
                <Col xl={2} lg={4} md={12} className="order-1">
                    <Row>
                        <Col lg={12} md={6} sm={6} xs={6} className="px-0 profile-picture">
                            <Image src={user.image} alt={user.image} className="h21 w21 profile-img profile-img-border" />
                            <Image src={saturn} alt={saturn} className="p-3 h21 w21 border border-danger profile-img nonebig blur-bg" />
                        </Col>

                        <Col lg={12} md={6} sm={6} xs={6} className="px-0 py-lg-3">
                            <Container fluid className="h32 w21 px-0 pb-3 border border-danger blur-bg">
                                <Nav variant="pills" className="flex-column pb-2 sections">
                                    <Nav.Item><Nav.Link className="text white small-medium pb-3 pt-1 py-md-1" eventKey="profile-data">Profile Data</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link className="text white small-medium pb-3 pt-1 py-md-1" eventKey="edit-profile">Edit Profile </Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link className="text white small-medium pb-3 pt-1 py-md-1" eventKey="join-team">Join Team</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link className="text white small-medium pb-3 pt-1 py-md-1" eventKey="create-team">Create Team</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link className="text white small-medium pb-3 pt-1 py-md-1" eventKey="edit-team" disabled={!hasTeams}>Manage Team</Nav.Link></Nav.Item>
                                </Nav>
                                {
                                    window.matchMedia("(min-width: 991px)").matches ? <CustomButton clickFunc={() => { localStorage.removeItem("token"); window.location.reload(); }}><p className="px-3 text white medium">Logout</p></CustomButton> : <></>
                                }
                            </Container>
                        </Col>

                        <Col lg={12} md={6} className="px-0 nonesmall">
                            <Image src={moon} alt={moon} className="p-3 h32 w21 border border-danger profile-img blur-bg" />
                        </Col>
                        {
                            window.matchMedia("(max-width: 991px)").matches ? <div className="pt-4"><CustomButton clickFunc={() => { localStorage.removeItem("token"); window.location.reload(); }}><p className="px-3 py-1 text white medium">Logout</p></CustomButton></div> : <></>
                        }
                    </Row>
                </Col>
                <Col xl={7} lg={7} md={12} className='order-3 order-md-2 right-side border border-danger blur-bg scrollable mb-3'>
                    <Tab.Content>
                        <Tab.Pane eventKey="profile-data">
                            <ProfileData user={user} teams={teams} ranks={user.ranks} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="edit-profile">
                            <ProfileEdit user={user} teams={teams} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="join-team">
                            <ProfileJoinTeam />
                        </Tab.Pane>
                        <Tab.Pane eventKey="create-team">
                            <CreateTeam />
                        </Tab.Pane>
                        <Tab.Pane eventKey="edit-team">
                            <ProfileEditTeam user={user} teams={teams.filter((team: TeamInterface) => { return team.captainId === user.id })} />
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
                {
                    <Col className="order-2 order-md-3">
                        <ProfilePrivateData {...user} />
                    </Col>
                }

            </Row>
        </Tab.Container>
    );
}

export default Profile;
