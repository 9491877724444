import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

export default function Verify() {
    const verify = (token: string) => {
        return axios.post(`${process.env.REACT_APP_URL}/auth/checkAccount`, { token });
    }
    
    const nav = useNavigate();
    const { token } = useParams();
    
    useEffect(() => {
        if (token) {
            verify(token).then(() => {
                setTimeout(() => {
                    nav('/login');
                }, 3000);
            });
        }
    }, []);

    return (
        <div className='text white'>
            <h4>Veți fi redirecționat în curând la pagina de Autentificare!</h4>;
        </div>
    )
}
