import "./ForgotPassword.css";
import React, { useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CustomButton from '../../common/CustomButton';
import axios from 'axios';
import * as Yup from 'yup';

function ForgotPassword() {
  const [forgotError, setForgotError] = useState('');

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email missing'),
      }),
    [],
  );

  const handleForgotPassword = async (
    values: { email: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    try {
      let nextPasswordResetRequest = window.localStorage.getItem(
        'nextPasswordResetRequest',
      );
      setForgotError('Wait...');
      
      const response = await sendForgotPasswordRequest(values);
      window.localStorage.setItem(
        'nextPasswordResetRequest',
        (Date.now() / 1000 + 69).toString(),
      );
      setForgotError('Please check your email');
      if (response.status) setForgotError(response.status);
      
    } catch (error: any) {
      setForgotError(error.response.data.errors);
    } finally {
      setSubmitting(false);
    }
  };

  const sendForgotPasswordRequest = async (values: { email: string }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/auth/forgot-password`,
      values,
    );
    return response.data;
  };

  return (
    <Container>
      <div className="align-items-center border border-margin border-danger px-md-5 py-2">
        <Formik
          initialValues={{
            email: '',
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleForgotPassword(values, { setSubmitting });
          }}
        >
          {(formik) => (
            <div className="ps-3 pe-3 pb-2">
              <p className="text white large justify w700 text-center p-3">
                Forgot Password
              </p>
              <Form>
                <Row className="mb-3 justify-content-around">
                  <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                    <p className="ms-2 text white medium justify w700">Email</p>
                    <Field
                      className="autofill p-1 border-margin border-danger text white small transparent-background large-field"
                      type="email"
                      name="email"
                      placeholder="Email"
                    />
                    <ErrorMessage name="email">
                      {(msg) => (
                        <p className="ms-2 text red small justify">{msg}</p>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
                {forgotError && (
                  <p className="text text-center red medium text-start pb-2">
                    {forgotError}
                  </p>
                )}
                <CustomButton>
                  <p className="text white medium justified mx-4">
                    Send Reset Link
                  </p>
                </CustomButton>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </Container>
  );
}

export default ForgotPassword;
