import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Image } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import "./DragAndDrop.css";

import thumbnail from "../../images/thumbnail.svg";

function DragAndDrop({
    accept, form, field
}) {
    const input = useRef();
    const [url, setUrl] = useState("");
    const [def, setDefault] = useState("");

    useEffect(() => {
        try {
            if (field.value && new URL(field.value)) {
                setUrl(field.value);
                setDefault(field.value);
            }
        } catch (error) {}
    }, [field.value]);

    function loadFile(file) {
        const reader = new FileReader();
        reader.onload = () => {
            setUrl(reader.result);
            form.setFieldValue(field.name, file);
            input.current.value = "";
        };
        reader.readAsDataURL(file);
    }

    function handleChange(event) {
        event.persist();
        if (event.target.files.length > 0) {
            loadFile(event.target.files[0]);
        }
    }

    function handleOnDrop(event) {
        event.persist();
        event.preventDefault();

        if (event.dataTransfer.items) {
            for (let item of event.dataTransfer.items) {
                if (item.kind === "file") {
                    loadFile(item.getAsFile());
                }
            }
        } else if (event.dataTransfer.files.length > 0) {
            loadFile(event.dataTransfer.files[0]);
        }

        if (event.dataTransfer.items) {
            event.dataTransfer.items.clear();
            return;
        }
        event.dataTransfer.clearData();
    }

    function handleOnDragOver(event) {
        event.persist();
        event.preventDefault();
    }

    function handleDeleteValue() {
        setUrl(def);
        form.setFieldValue(field.name, def);
    }

    return (
        <>
            <div draggable="true" onDrop={handleOnDrop} onDragOver={handleOnDragOver}>
                <div className="icon-wrapper">
                    {url && url != field.value && <FaTrashAlt onClick={handleDeleteValue} className="icon delete" size={30} />}
                </div>
                <label className="w-100 cursor-pointer" htmlFor={`id-${field.name}`}>
                    <Row className="w-100 mx-0">
                        <Col xs={3} className="px-1 middle">
                            {url && <img className="image h10 w10 profile-img-border" src={url} alt={url} />}
                            {!url && <img className="image h10 w10 profile-img-border" src={def} alt={def} />}
                        </Col>
                        <Col xs={9} className="border border-danger px-4 py-2 text-center middle">
                            <Image className="sml" src={thumbnail} alt={thumbnail} />
                            <div className='split-two'>
                                <p className='text grey-light medium-small inline'>Click to upload </p>
                                <p className='text grey-dark medium-small inline'>or drag and drop SVG or PNG (max. 1Mb)</p>
                            </div>
                        </Col>
                    </Row>
                </label>
            </div>
            <input
                ref={input}
                className="input"
                accept={accept}
                type="file"
                id={`id-${field.name}`}
                name={field.name}
                onChange={handleChange}
            />
        </>
    );
}

export default DragAndDrop;
