import React, { useEffect, useState } from "react";
import "./Leaderboard.css";
import Table from "react-bootstrap/Table";
import Number from "./../../images/Nr.svg";
import Profile from "./../../images/Profile_name.svg";
import Score from "./../../images/Score.svg";
import ReactPaginate from "react-paginate";
import { getLeaderboard } from "./Api";
import cup from "./../../images/cup.svg";

interface BetterEntry {
  participant: string;
  score: number;
}

export default function Leaderboard() {
  const [lead, setLead] = useState<BetterEntry[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 14;

  const getParticipants = async () => {
    const leadboard = await getLeaderboard();
    const leadArray = Object.entries(leadboard).map(([participant, score]) => ({
      participant: participant as string,
      score: parseInt(score as string),
    }));

    const sortedArray = leadArray.sort((a, b) => {
      return b.score - a.score;
    });
    setLead(sortedArray);
  };

  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;

  const displayedUsers = lead?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil((lead?.length || 0) / itemsPerPage);

  useEffect(() => {
    getParticipants();
  }, []);

  const handleChangePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const [currentGamePage, setCurrentGamePage] = useState<string>("csgo");
  const changeGamePage = (Leaderboard: string) => {
    setCurrentGamePage(Leaderboard);
  };

  const td_class = "py-2 px-1 ";
  const pdata_class =
    "f-height max-1 border border-danger medium text w700 mx-1 py-1 px-2";
  const pdata_class_special =
    "f-height max-1 border border-danger medium text w700 mx-1 pb-1 pe-xl-2 pe-lg-0";

  return (
    <div className="general m-auto my-5">
      <h1 className="title text white pb-3 true-medium">
        Competitions Leaderboard
      </h1>
      <div className="row">
        <div className="col">
          <h5 className="title text-white text-start">
            Filter by competition:
          </h5>
          <div className="col text-start">
            <button
              className={`csgo ${currentGamePage === "csgo" ? "active" : ""}`}
              onClick={() => changeGamePage("csgo")}
            ></button>
            <button
              className={`lol ${currentGamePage === "lol" ? "active" : ""}`}
              onClick={() => changeGamePage("lol")}
            ></button>
            <button
              className={`rl ${currentGamePage === "rl" ? "active" : ""}`}
              onClick={() => changeGamePage("rl")}
            ></button>
            <button
              className={`fifa ${currentGamePage === "fifa" ? "active" : ""}`}
              onClick={() => changeGamePage("fifa")}
            ></button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="custom-height m-auto border border-danger px-xl-5 px-lg-3 py-lg-4 px-sm-1 py-sm-1 blur-bg">
            <Table responsive className="bets_table">
              {currentGamePage === "csgo" && (
                <>
                  <h1 className="white leaderboardTextTitle">Check out the CS2 leaderboard at:</h1>
                  <a href="https://www.faceit.com/en/championship/9286f9d0-eee9-40e9-a6fa-668a53eb4a6a/Group%20Stage%20CS2/standings/leaderboard" className="leaderboardText">CS2 Brackets</a>
                </>
              )}
              {currentGamePage === "lol" && (
                <>
                  <h1 className="white leaderboardTextTitle">Check out the LOL leaderboard at:</h1>
                  <a href="https://www.faceit.com/en/championship/e102a47a-122d-469d-926b-d6ebc8602968/Single%20Elimination%20LOL/results" className="leaderboardText">LOL Brackets</a>
                </>
              )}
              {currentGamePage === "rl" && (
                <>
                  <h1 className="white leaderboardTextTitle">Check out the RL leaderboard at:</h1>
                  <a href="https://challonge.com/h8ytnmaz" className="leaderboardText">RL Brackets</a>
                </>
              )}
              {currentGamePage === "fifa" && (
                <>
                  <thead className="white">
                    <tr>
                      <th scope="col" className={`${td_class} hide-on-md`}>
                        <img src={Number} alt="Number" className="images" />
                      </th>
                      <th scope="col" className={`${td_class} hide-on-md`}>
                        <img src={Profile} alt="Number" className="images" />
                      </th>
                      <th scope="col" className={`${td_class} hide-on-md`}>
                        <img src={Score} alt="Number" className="images" />
                      </th>
                    </tr>
                  </thead>
                  <tbody className="white">
                    {displayedUsers?.map((l, idx) => (
                      <React.Fragment key={idx}>
                        <tr>
                          <th
                            scope="row"
                            className={td_class + "no-column hide-on-md"}
                          >
                            <p className={pdata_class}>
                              {(currentPage - 1) * itemsPerPage + idx + 1}
                            </p>
                          </th>
                          <th scope="col" className={td_class}>
                            <p
                              className={
                                "inline justify-content-around flex " +
                                `${pdata_class_special}`
                              }
                            >
                              <div className="w-100 text-center px-1">
                                <span className="max-1 pt-1">
                                  {l.participant}
                                </span>
                              </div>
                            </p>
                          </th>
                          <th scope="col" className={td_class}>
                            <p className={pdata_class}>
                              {l.score}
                              <img src={cup} alt="cupa" className="ms-3" />
                            </p>
                          </th>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </>
              )}
            </Table>
          </div>
        </div>
      </div>
      <div className="pagination-container">
        <div className="pagination">
          <ReactPaginate
            previousLabel="Prev"
            nextLabel="Next"
            breakLabel="..."
            pageCount={currentGamePage === "fifa" ? totalPages : 1}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={(selected) => handleChangePage(selected.selected + 1)}
            containerClassName="pagination"
            activeClassName="active"
            forcePage={currentPage - 1}
            previousLinkClassName="custom-previous-link"
            nextLinkClassName="custom-next-link"
          />
        </div>
      </div>
    </div>
  );
}
